package della8.web.support

import della8.core.support.Deployment

val deployment = Deployment(
    applicationKey = "C12C001D-AD24-4FFE-B3B8-62E44F740E67",
    applicationSecret = "9444D316-04E8-4DE3-A2C6-4F45C83FE61C",
    redirect = "",
    isSandbox = true,
    version = "2.0.6-SNAPSHOT",
    della8Home =  "https://della8.sandbox.techla.io",
    della8Finder =  "https://della8.sandbox.techla.io/finder",
    build = 112,
)