package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.screens.AgreementScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier

val AgreementView = FC<PropsWithChildren>("AgreementView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(AgreementScreen.ViewModel.None as AgreementScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val (showModal, setShowModal) = useState(false)
    val (count, setCount) = useState(0)

    val scopedCall = scopedCall<AgreementScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val call = call<AgreementScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val mainCall = mainCall<AgreementScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is AgreementScreen.ViewModel.None ->
                scopedCall(coroutineScope) { AgreementScreen.start(sceneInputOf(store, viewModel)) }

            is AgreementScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        scopedCall(coroutineScope) { AgreementScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                    }
                }
            }

            is AgreementScreen.ViewModel.Approving -> {
                scopedCall(coroutineScope) { AgreementScreen.approve(sceneInputOf(store, viewModel)) }
                setCount(1)
            }

            else -> {}
        }
    }

    suspend fun check() {
        viewModel.asWaiting?.let {
            call { AgreementScreen.check(sceneInputOf(store, viewModel)) }
        }
    }

    useAsyncEffect(count) { coroutineScope ->
        if (count > 0) {
            if (coroutineScope.isActive) {
                delay(1000L)
                check()
                viewModel.asWaiting?.let {
                    setCount(count + 1)
                }
                viewModel.asApproved?.let {
                    setCount(0)
                }
                viewModel.asReady?.let {
                    setCount(0)
                }
            }
        }
    }

    suspend fun onAgreement(option: DesignSystem.Option) {
        call { AgreementScreen.update(sceneInputOf(store, viewModel), agreement = option) }
    }

    fun onApprove() {
        mainCall { AgreementScreen.validate(sceneInputOf(store, viewModel)) }
    }

    suspend fun onSuccess() {
        call { AgreementScreen.success(sceneInputOf(store, viewModel)) }
    }

    fun logout() {
        mainCall { AgreementScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }

    fun recover() {
        mainCall { AgreementScreen.success(sceneInputOf(store, viewModel)) }
    }

    main {
        when (viewModel) {
            is AgreementScreen.ViewModel.Ready, is AgreementScreen.ViewModel.Empty -> d8Navigation(
                design = viewModel.navigation,
                className = "nav-top",
                onClick = standardNavigation(navigate)
            )

            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        }

        if (viewModel is AgreementScreen.ViewModel.Empty) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")

                    bCol {
                        xs = 12; md = 5
                        d8Image(className = "d-block mx-auto", design = viewModel.image)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "text-center", design = viewModel.info)
                    }
                }
            }
        }

        if (viewModel is AgreementScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-3 px-md-5 position-relative n-bottom-space-160 n-top-space")

                bRow {
                    className = className("pb-4 justify-content-center")

                    bCol {
                        xs = 12; md = 5
                        d8Image(className = "d-block mx-auto", design = viewModel.image)
                        d8Text(className = "text-center", design = viewModel.title)

                    }
                    bRow {
                        D8SelectInput {
                            design = viewModel.agreements
                            onClick = ::onAgreement
                        }
                    }
                }
                bContainer {
                    className = className("px-4 px-md-5 n-white-bg-box")

                    bRow {
                        bCol {
                            className = className("p-0 pt-4")

                            xs = 12; md = 6
                            d8Text(className = "d-block m-0", design = viewModel.from)
                            d8Text(className = "d-block m-0 pt-3 pb-2", design = viewModel.signedBy)
                            div {
                                className = className("d-flex justify-content-start pb-2")

                                viewModel.profiles.map { profile -> d8Element(design = profile) }
                            }
                        }
                        bCol {
                            className = className("p-0 pt-4")
                            xs = 12; md = 6
                            d8Text(className = "d-block m-0 text-end", design = viewModel.version)
                            div {
                                className = className("d-block text-end")
                                d8Button(
                                    className = "py-1 pe-0",
                                    design = viewModel.prevVersion,
                                    onClick = { setShowModal(true) })
                            }
                            div {
                                className = className("d-block text-end")
                                d8Button(
                                    className = "py-1 pe-0",
                                    design = viewModel.download,
                                    onClick = { })

                            }
                        }
                    }
                    bRow {
                        className = className("w-100")
                        hr { }
                    }
                    bRow {
                        className = className("px-3 px-md-5 py-4 n-agreement-look")
                        d8Text(design = viewModel.terms)
                    }
                    bRow {
                        className = className("pb-4")
                    }
                }
            }
            bContainer {
                fluid = true
                className = className("py-3 n-sign-contract")

                d8Button(className = "d-block px-5 mx-auto", design = viewModel.approve, onClick = ::onApprove)
            }

            bModal {
                show = (showModal)
                onHide = { setShowModal(false) }
                centered = true
                size = "lg"
                scrollable = true

                bModalHeader {
                    closeButton = true
                    +""
                }
                bModalBody {
                    bContainer {
                        bModalBody {
                            params["objectId"]?.let { objectId ->
                                params["agreementId"]?.let { agreementId ->
                                    agreementHistoryView(objectId = Identifier(objectId), agreementId = Identifier(agreementId))
                                }
                            }
                        }
                    }
                }
            }
        }

        if (viewModel is AgreementScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is AgreementScreen.ViewModel.Approving) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is AgreementScreen.ViewModel.Waiting) {
            bContainer {
                className = className("px-3 px-md-5 position-relative n-bottom-space-160 n-top-space")

                bRow {
                    className = className("pb-4 justify-content-center")

                    d8Text(design = viewModel.scanQr)
                    div {
                        className = className("d-grid")
                        if (viewModel.state.qrCode != null && viewModel.state.qrCode != undefined) {
                            D8BankId { this.value = viewModel.state.qrCode!!; this.showOtherDevice = false }
                        }
                    }
                    d8Button(design = viewModel.cancel, onClick = ::onSuccess)
                }
            }
        }

        if (viewModel is AgreementScreen.ViewModel.Approved) {
            Della8.Success { design = viewModel.success; onSuccess = ::onSuccess }
        }

        if (viewModel is AgreementScreen.ViewModel.Failed) {
            d8failure(design = viewModel.failure, onLogout = ::logout, onRecover = ::recover)
        }
    }
}