package della8.core.services

import della8.core.support.*
import techla.base.*
import techla.conversation.*

suspend fun Store.listMessages(): ActionOutcome<List<Message>> {
    return listMessages(rightOf(Key("#artiklar")))
        .map { (actions, messages) ->
            actions + Store.Action.ArticlesLoaded(messages) to messages
        }
        .onNotSuccess { techla_log("WARN: $it") }
}

/*
suspend fun Store.listFeeds(key: Key<Feed>): ActionOutcome<List<Feed>> {
    val api = conversationAPI
    return measureAPI(ConversationAPIResource.ListFeeds(null, listOf(key)), api) {
        api.listFeeds(null, listOf(key))
            .noActions()
            .onNotSuccess { techla_log("WARN: $it") }
    }
}
 */

suspend fun Store.listMessages(feed: Either<Identifier<Feed>, Key<Feed>>): ActionOutcome<List<Message>> =
    ConversationEndpoint.listMessages(this, feed)
        .noActions()


suspend fun Store.getMessage(messageId: Identifier<Message>): ActionOutcome<Message> {
    val api = conversationAPI
    return measureAPI(ConversationAPIResource.GetMessage(messageId), api) {
        api.getMessage(messageId)
            .noActions()
            .onNotSuccess { techla_log("WARN: $it") }
    }
}

private suspend fun Store.getFeedMessage(id: Identifier<Message>) =
    ConversationEndpoint.getMessage(this, id)

suspend fun Store.getMessage(obj: Object, id: Identifier<Message>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getFeedMessage(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))

    }

private suspend fun Store.createMessage(create: Message.Create) =
    ConversationEndpoint.createMessage(this, create)


suspend fun Store.createMessage(obj: Object, create: Message.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createMessage(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }


private suspend fun Store.modifyMessage(modify: Message.Modify, messageId: Identifier<Message>) =
    ConversationEndpoint.modifyMessage(this, modify, messageId)


suspend fun Store.modifyMessage(obj: Object, modify: Message.Modify, messageId: Identifier<Message>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).modifyMessage(modify, messageId)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }


private suspend fun Store.deleteMessage(messageId: Identifier<Message>) =
    ConversationEndpoint.deleteMessage(this, messageId)


suspend fun Store.deleteMessage(obj: Object, messageId: Identifier<Message>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).deleteMessage(messageId)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }


private suspend fun Store.post(entry: Feed.Entry, to: List<Key<Feed>>?) =
    ConversationEndpoint.post(this, entry, to)


suspend fun Store.post(obj: Object, entry: Feed.Entry, to: List<Key<Feed>>?) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).post(entry, to)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.modify(id: Identifier<Message>, edit: Message.Modify) =
    ConversationEndpoint.modify(this, id, edit)


suspend fun Store.modify(obj: Object, id: Identifier<Message>, edit: Message.Modify) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).modify(id, edit)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.react(messageId: Identifier<Message>, reaction: Reaction.Create) =
    ConversationEndpoint.react(this, messageId, reaction)


suspend fun Store.react(obj: Object, messageId: Identifier<Message>, reaction: Reaction.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).react(messageId, reaction)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.prepareGroupFeed() =
    ConversationEndpoint.prepareGroupFeed(this)

suspend fun Store.prepareGroupFeed(obj: Object) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).prepareGroupFeed()
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.personal(restart: Boolean, startIndex: Int, endIndex: Int) =
    ConversationEndpoint.personal(this, restart, startIndex, endIndex)

suspend fun Store.personal(obj: Object, restart: Boolean = false, startIndex: Int = 0, endIndex: Int = 20) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).personal(restart, startIndex!!, endIndex!!)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }
private suspend fun Store.flag(id: Identifier<Message>) =
    ConversationEndpoint.flag(this, id)

suspend fun Store.flag(obj: Object, id: Identifier<Message>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).flag(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.getReactionsWithMessage(id: Identifier<Message>) =
    ConversationEndpoint.getReactionsWithMessage(this, id)

suspend fun Store.getReactionsWithMessage(obj: Object, id: Identifier<Message>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getReactionsWithMessage(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

val Store.conversationAPI
    get() =
        ConversationAPI(httpClient).also { api ->
            api.token = userToken ?: applicationToken
            api.host = if (deployment.isSandbox) ConversationAPI.sandbox else ConversationAPI.shared
        }


object ConversationEndpoint {

    var listMessages: suspend (store: Store, feed: Either<Identifier<Feed>, Key<Feed>>) -> Outcome<List<Message>> = { store, feed ->
        val api = store.conversationAPI
        measureAPI(ConversationAPIResource.ListMessages(feed.leftOrNull(), feed.rightOrNull()), api) {
            api.listMessages(feed)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
    var createMessage: suspend (store: Store, create: Message.Create) -> ActionOutcome<Message> = { store, create ->
        if (store.demoMode)
            Demo.listAllMessages.map { it.first() }.noActions()
        else {
            store.withUserToken { update ->
                val api = update.conversationAPI
                measureAPI(ConversationAPIResource.CreateMessage(create), api) {
                    api.createMessage(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var getMessage: suspend (store: Store, id: Identifier<Message>) -> ActionOutcome<Message> = { store, id ->
        if (store.demoMode)
            Demo.listAllMessages.map { it.first() }.noActions()
        else {
            store.withUserToken { update ->
                val api = update.conversationAPI
                measureAPI(ConversationAPIResource.GetMessage(id), api) {
                    api.getMessage(id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var modifyMessage: suspend (store: Store, modify: Message.Modify, messageId: Identifier<Message>) -> ActionOutcome<Message> = { store, modify, messageId ->
        if (store.demoMode)
            Demo.listAllMessages.map { it.first() }.noActions()
        else {
            store.withUserToken { update ->
                val api = update.conversationAPI
                measureAPI(ConversationAPIResource.Modify(messageId, modify), api) {
                    api.modify(messageId, modify)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var deleteMessage: suspend (store: Store, id: Identifier<Message>) -> ActionOutcome<Unit> = { store, id ->
        if (store.demoMode) successfulOf(Unit).noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.conversationAPI
                measureAPI(ConversationAPIResource.DeleteMessage(id), api) {
                    api.deleteMessage(id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var post: suspend (store: Store, entry: Feed.Entry, to: List<Key<Feed>>?) -> ActionOutcome<Feed.Update> = { store, entry, to ->
        store.withUserToken { updated ->
            val api = updated.conversationAPI
            measureAPI(ConversationAPIResource.Post(post = Feed.Post(keys = to, entry = entry)), api) {
                api.post(to = to, entry = entry)
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }

    var modify: suspend (store: Store, id: Identifier<Message>, edit: Message.Modify) -> ActionOutcome<Message> = { store, id, edit ->
        store.withUserToken { updated ->
            val api = updated.conversationAPI
            measureAPI(ConversationAPIResource.Modify(id, edit), api) {
                api.modify(id, edit)
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }
    var react: suspend (store: Store, id: Identifier<Message>, reaction: Reaction.Create) -> ActionOutcome<Message> = { store, id, reaction ->
        if (store.demoMode) Demo.listAllMessages.map { it.first() }.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.conversationAPI
                measureAPI(ConversationAPIResource.React(id, reaction), api) {
                    api.react(id, reaction)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }
    var personal: suspend (store: Store, restart: Boolean, startIndex: Int, endIndex: Int) -> ActionOutcome<List<Message>> = { store, restart, startIndex, endIndex ->
        store.withUserToken { updated ->
            val api = updated.conversationAPI
            measureAPI(ConversationAPIResource.Personal(restart, startIndex, endIndex), api) {
                api.personal(restart, startIndex, endIndex)
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }
    var flag: suspend (store: Store, id: Identifier<Message>) -> ActionOutcome<Message> = { store, id ->
        store.withUserToken { updated ->
            val api = updated.conversationAPI
            measureAPI(ConversationAPIResource.Flag(id, Message.Flags(removed = true)), api) {
                api.flag(id, Message.Flags(removed = true))
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }
    var getReactionsWithMessage: suspend (store: Store, id: Identifier<Message>) -> ActionOutcome<List<Reaction>> = { store, id ->
        store.withUserToken { updated ->
            val api = updated.conversationAPI
            measureAPI(ConversationAPIResource.GetReactionsWithMessage(id), api) {
                api.getReactions(id)
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }

    var prepareGroupFeed: suspend (store: Store) -> ActionOutcome<Feed> = { store ->
        store.withUserToken { updated ->
            val api = updated.conversationAPI
            measureAPI(ConversationAPIResource.PrepareGroupFeed, api) {
                api.prepareGroupFeed()
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }
}



