package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.SettingsScreen
import della8.core.support.Location
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import kotlinx.coroutines.isActive
import kotlinx.js.get
import react.*
import react.dom.html.ButtonType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log

val Settings = FC<PropsWithChildren>("Settings") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(SettingsScreen.ViewModel.None as SettingsScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val (showModal, setShowModal) = useState(false)

    val scopedCall = scopedCall<SettingsScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<SettingsScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    suspend fun handleSave(formData: Map<String, FormValue>) {
        val name = formData.getValue(SettingsScreen.Header.name.id).string
        mainCall { SettingsScreen.validate(sceneInputOf(store, viewModel), name = name) }
    }

    suspend fun handleDelete() {
        mainCall { SettingsScreen.delete(sceneInputOf(store, viewModel)) }
    }

    suspend fun handleSaved() {
        params["objectId"]?.let { objectId ->
            SettingsScreen.load(sceneInputOf(store, viewModel), Identifier(objectId)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                navigate(Location.Landing.route)

            }
        }
    }

    fun handleDeleted() {
        navigate("/landing")
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is SettingsScreen.ViewModel.None ->
                SettingsScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }

            is SettingsScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    SettingsScreen.load(sceneInputOf(store, viewModel), Identifier(objectId))
                        .also { (viewModel, actions) ->
                            if (coroutineScope.isActive) {
                                setViewModel(viewModel)
                                dispatch(actions)
                            }
                        }
                }
            }

            is SettingsScreen.ViewModel.Saving -> {
                scopedCall(coroutineScope) { SettingsScreen.save(sceneInputOf(store, viewModel)) }
            }

            is SettingsScreen.ViewModel.Ready -> {
            }

            is SettingsScreen.ViewModel.Failed ->
                techla_log("failed ")

            else -> {}
        }
    }


    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        if (viewModel is SettingsScreen.ViewModel.Ready) {
            bContainer {
                className = className(
                    listOfNotNull("px-5 position-relative n-bottom-space n-top-space")
                )
                bRow {
                    className = className(
                        listOfNotNull("pb-4 justify-content-center")
                    )
                    bCol {
                        xs = 12; md = 5
                        D8Form {
                            this.onSubmit = ::handleSave

                            d8Text(className = "text-center", design = viewModel.title)
                            d8TextInput(classNameInput = "mb-2", design = viewModel.name)
                            div {
                                className = className("d-grid")
                                d8Button(className = "btn-lg mt-3 mb-3", design = viewModel.save, type = ButtonType.submit)
                                d8Button(className = "d8-ruby-bg btn-lg", design = viewModel.delete, onClick = { setShowModal(true) })
                            }
                        }
                    }
                }
            }
            d8Modal(design = viewModel.confirmDelete, onClickFirst = ::handleDelete, onClickSecond = { setShowModal(false) }, onHide = { setShowModal(false) }, show = showModal)
        }

        if (viewModel is SettingsScreen.ViewModel.Saved) {
            bContainer {
                className = className("px-5 n-top-space")

                bRow {
                    className = className("justify-content-center")

                    bCol {
                        xs = 12; md = 6
                        d8Icon(className = "d-block mx-auto", design = viewModel.icon)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "text-center", design = viewModel.info)
                    }
                }
                bRow {
                    bCol {
                        className = className("p-0 pb-4 d-flex justify-content-center")
                        d8Button(
                            className = "btn-lg mt-3 px-5",
                            design = viewModel.done,
                            onClick = { handleSaved() }
                        )
                    }
                }
            }
        }

        if (viewModel is SettingsScreen.ViewModel.Deleted) {
            bContainer {
                className = className("px-5 n-top-space")

                bRow {
                    className = className("justify-content-center")

                    bCol {
                        xs = 12; md = 6
                        d8Icon(className = "d-block mx-auto", design = viewModel.icon)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "text-center", design = viewModel.info)
                    }
                }
                bRow {
                    bCol {
                        className = className("p-0 pb-4 d-flex justify-content-center")
                        d8Button(
                            className = "btn-lg mt-3 px-5",
                            design = viewModel.done,
                            onClick = { handleDeleted() }
                        )
                    }
                }
            }
        }

        if (viewModel is SettingsScreen.ViewModel.Saving) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is SettingsScreen.ViewModel.Failed) {
            bContainer {
                className = className(
                    listOfNotNull("px-5 n-top-space")
                )
                d8failure(viewModel.failure)
            }
        }
    }
}
