package della8.web.components

import bootstrap.*
import della8.core.native.parseDecimal
import della8.core.support.DesignSystem
import della8.web.support.className
import della8.web.support.useReactScope
import della8.web.theme.Della8Colors
import della8.web.theme.Della8Theme
import della8.web.theme.Design
import della8.web.theme.background
import dom.html.HTMLInputElement
import dom.html.HTMLSelectElement
import dom.html.HTMLTextAreaElement
import io.ktor.client.request.forms.*
import io.ktor.utils.io.core.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.js.asList
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.w3c.dom.events.Event
import org.w3c.files.FileReader
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.p
import techla.base.leftOf
import techla.base.techla_log
import techla.storage.Asset
import web.file.File

fun ArrayBuffer.toByteArray(): ByteArray = Int8Array(this).unsafeCast<ByteArray>()

external interface D8BooleanInputProps : PropsWithChildren {
    var classNameInput: String
    var classNameLabel: String
    var design: DesignSystem.BooleanInput
    var defaultChecked: Boolean
    var onChange: suspend ((Boolean) -> Unit)
}

val D8BooleanInput = FC<D8BooleanInputProps>("D8BooleanInput") { props ->
    val formData = useContext(FormContext)
    val key = props.design.header?.id ?: props.design.title ?: ""
    formData[key] = FormValue.BooleanValue(props.design.value ?: false)

    fun onChange(event: Event) {
        val target = event.target as HTMLInputElement
        formData.put(key, FormValue.BooleanValue(target.checked))

        MainScope().launch {
            props.onChange(target.checked)
        }
    }


    when (props.design) {
        else -> when (props.design.visible) {
            true -> {
                bFormCheck {
                    this.className = className(props.classNameInput)
                    this.key = props.design.header?.id ?: props.design.title ?: ""
                    this.type = "checkbox"
                    this.onChange = ::onChange
                    this.disabled = props.design.disabled
                    this.id = props.design.title ?: ""
                    this.defaultChecked = props.defaultChecked

                }
                bFormLabel {
                    this.htmlFor = props.design.title ?: ""
                    this.className = className(
                        listOfNotNull(props.classNameLabel)
                    )
                    +props.design.title.let {
                        it ?: ""
                    }
                }
            }

            else -> {
            }
        }
    }
}

fun ChildrenBuilder.d8BooleanInput(
    design: DesignSystem.BooleanInput,
    classNameInput: String = "",
    classNameLabel: String = "",
    defaultChecked: Boolean = false,
    onChange: suspend ((Boolean) -> Unit) = {}
) = D8BooleanInput {
    this.classNameInput = classNameInput
    this.classNameLabel = classNameLabel
    this.design = design
    this.defaultChecked = defaultChecked
    this.onChange = onChange
}

external interface D8TextInputProps : PropsWithChildren {
    var classNameInput: String
    var classNameLabel: String
    var design: DesignSystem.TextInput
    var overrideValue: String?
    var overrideValueInt: Int?
    var overrideValueDouble: Double?
    var onChange: ((String) -> Unit)
    var onChangeDouble: ((Pair<String, Double?>) -> Unit)
    var onChangeInt: ((Pair<String, Int?>) -> Unit)
}

val D8TextInput = FC<D8TextInputProps>("D8TextInput") { props ->
    val formData = useContext(FormContext)
    val key = props.design.header.id
    formData[key] = FormValue.StringValue(props.design.value ?: "")

    fun onChange(event: Event) {
        (event.target as? HTMLInputElement)?.let { target ->
            formData.put(key, FormValue.StringValue(target.value))

            val stringValue = when (props.design.input) {
                DesignSystem.Input.REGISTRATION -> target.value.uppercase()
                else -> target.value
            }
            val doubleValue = parseDecimal(stringValue).second
            val intValue = doubleValue?.toInt()

            when (props.design.input) {
                DesignSystem.Input.NUMBER ->
                    props.onChangeInt(stringValue to intValue)

                DesignSystem.Input.DECIMAL ->
                    props.onChangeDouble(stringValue to doubleValue)

                else ->
                    props.onChange(stringValue)
            }
        }
        (event.target as? HTMLTextAreaElement)?.let { target ->
            formData.put(key, FormValue.StringValue(target.value))
            props.onChange(target.value)


        }
    }

    if (props.design.visible) {
        if (props.design.title != null) {
            bFormLabel {
                htmlFor = props.design.header.id
                className = className(listOf("d8-subhead d8-marine", props.classNameLabel))
                +props.design.title.let {
                    it ?: ""
                }
            }
        }
        bInputGroup {
            when (props.design.input) {
                DesignSystem.Input.DECIMAL -> {
                    bFormControl {
                        className = className(props.classNameInput)
                        this.key = props.design.header.id
                        id = props.design.header.id
                        type = "number"
                        step = "0.1"
                        min = "0"
                        defaultValue = props.overrideValueDouble ?: props.design.value
                        disabled = props.design.disabled
                        onChange = ::onChange
                        placeholder = props.design.placeholder ?: ""
                    }
                }

                DesignSystem.Input.NUMBER -> {
                    bFormControl {
                        className = className(props.classNameInput)
                        this.key = props.design.header.id
                        id = props.design.header.id
                        type = "number"
                        step = "1"
                        min = "0"
                        defaultValue = props.overrideValueInt ?: props.design.value ?: ""
                        disabled = props.design.disabled
                        onChange = ::onChange
                        placeholder = props.design.placeholder ?: ""
                    }
                }

                else -> {
                    bFormControl {
                        className = className(props.classNameInput)
                        this.key = props.design.header.id
                        id = props.design.header.id
                        type = props.design.input.toString()
                        if (props.design.input == DesignSystem.Input.NOVEL) {
                            `as` = "textarea"
                        }
                        if (props.design.input == DesignSystem.Input.NOVEL) {
                            rows = props.design.rows
                        }
                        defaultValue = props.overrideValue ?: props.design.value ?: ""
                        disabled = props.design.disabled
                        onChange = ::onChange
                        placeholder = props.design.placeholder ?: ""
                    }
                }

            }

            props.design.unit?.let {
                bInputGroupText {
                    +it
                }
            }
        }
        props.design.status?.message?.let {
            bFormControlFeedback {
                type = it
                className = className(listOfNotNull(props.classNameLabel, Della8Colors.designColor(DesignSystem.Color.RUBY)))
                +it
            }
        }
    }
}

fun ChildrenBuilder.d8TextInput(
    design: DesignSystem.TextInput,
    classNameInput: String = "",
    classNameLabel: String = "",
    overrideValue: String? = null,
    overrideValueInt: Int? = null,
    overrideValueDouble: Double? = null,
    onChange: ((String) -> Unit) = {},
    onChangeDouble: ((Pair<String, Double?>) -> Unit) = {},
    onChangeInt: ((Pair<String, Int?>) -> Unit) = {}
) = D8TextInput {
    this.classNameInput = classNameInput
    this.classNameLabel = classNameLabel
    this.design = design
    this.overrideValue = overrideValue
    this.overrideValueInt = overrideValueInt
    this.overrideValueDouble = overrideValueDouble
    this.onChange = onChange
    this.onChangeDouble = onChangeDouble
    this.onChangeInt = onChangeInt
}


external interface D8FileInputProps : PropsWithChildren {
    var classNameInput: String
    var classNameLabel: String
    var design: DesignSystem.FileInput
    var overrideValue: Any?
    var onChange: ((Asset.Create) -> Unit)
    var onChangeList: ((List<Asset.Create>) -> Unit)
}

val D8FileInput = FC<D8FileInputProps>("D8FileInput") { props ->
    val formData = useContext(FormContext)
    val key = props.design.header.id
//    formData[key] = FormValue.StringValue(props.design.value ?: "")

    val fileType = when (props.design.fileType) {
        DesignSystem.FileType.PDF -> ""
        DesignSystem.FileType.AUDIO -> "audio/*"
        DesignSystem.FileType.VIDEO -> "video/*"
        DesignSystem.FileType.IMAGE -> "image/*"
        else -> ""
    }

    fun loadFiles(files: List<File>, onComplete: (List<Asset.Create>) -> Unit) {
        var remainingFiles = files.size
        val assets = mutableListOf<Asset.Create>()

        files.forEach { file ->
            val reader = FileReader()
            reader.onload = {
                val data = InputProvider { ByteReadPacket(reader.result.unsafeCast<ArrayBuffer>().toByteArray()) }
                assets.add(Asset.Create(name = file.name, data = data, contentType = file.type))
                remainingFiles--

                if (remainingFiles == 0) {
                    onComplete(assets)
                }
            }
            reader.readAsArrayBuffer(file)
        }
    }


    fun onChange(event: Event) {
        val target = event.target as HTMLInputElement
        val file = target.files?.item(0)
        val files = target.files

        if (files != null && files.asList().isNotEmpty() && props.design.multiple) {
            loadFiles(files.asList()) { loadedAssets ->
                props.onChangeList(loadedAssets)
            }
        } else if (file != null) {
            val reader = FileReader()
            reader.readAsArrayBuffer(file)
            reader.onload = {
                val data = InputProvider { ByteReadPacket(reader.result.unsafeCast<ArrayBuffer>().toByteArray()) }

                val asset = Asset.Create(name = file.name, data = data, contentType = file.type)
                props.onChange(asset)
            }
        }
    }

    if (props.design.visible) {
        if (props.design.title != null) {
            div {
                className = className("d-flex flex-column align-items-start")
                bFormLabel {
                    htmlFor = props.design.header.id
                    className = className(listOf(props.classNameLabel, if (props.design.invisible) "btn btn-transparent" else "d8-subhead d8-marine"))
                    +props.design.title.let {
                        it ?: ""
                    }
                }
                if (props.design.invisible)
                    props.design.status?.message?.let {
                        bFormControlFeedback {
                            type = it
                            className = className(listOfNotNull(props.classNameLabel, Della8Colors.designColor(DesignSystem.Color.RUBY)))
                            +it
                        }
                    }
            }
        }
        if (props.design.invisible) bFormControl {
            className = className(props.classNameInput, "invisible w-0 d-none")
            this.key = props.design.header.id
            id = props.design.header.id
            type = "file"
            disabled = props.design.disabled
            onChange = ::onChange
            placeholder = props.design.placeholder ?: ""
            if (props.design.fileType != DesignSystem.FileType.ALL)
                accept = fileType
            if (props.design.multiple)
                this.multiple = "multiple"
        } else
            bInputGroup {
                bFormControl {
                    className = className(props.classNameInput)
                    this.key = props.design.header.id
                    id = props.design.header.id
                    type = "file"
                    disabled = props.design.disabled
                    onChange = ::onChange
                    placeholder = props.design.placeholder ?: ""
                    if (props.design.fileType != DesignSystem.FileType.ALL)
                        accept = fileType
                    if (props.design.multiple)
                        this.multiple = "multiple"
                }
            }
    }
    if (!props.design.invisible)
        props.design.status?.message?.let {
            bFormControlFeedback {
                type = it
                className = className(listOfNotNull(props.classNameLabel, Della8Colors.designColor(DesignSystem.Color.RUBY)))
                +it
            }
        }
}


// TODO - STYLE
fun ChildrenBuilder.d8FileInput(
    design: DesignSystem.FileInput,
    classNameInput: String = "",
    classNameLabel: String = "",
    overrideValue: Any? = null,
    onChange: ((Asset.Create) -> Unit) = {},
    onChangeList: ((List<Asset.Create>) -> Unit) = {},

    ) = D8FileInput {
    this.classNameInput = classNameInput
    this.classNameLabel = classNameLabel
    this.design = design
    this.overrideValue = overrideValue
    this.onChange = onChange
    this.onChangeList = onChangeList

}

external interface D8MovieInputProps : PropsWithChildren {
    var classesInput: String
    var classesLabel: String
    var design: DesignSystem.MovieInput
    var onChange: suspend ((DesignSystem.Option) -> Unit)
}

val D8MovieInput = FC<D8MovieInputProps>("D8MovieInput") { props ->
    val formData = useContext(FormContext)
    val key = props.design.header?.id ?: props.design.title ?: ""
    formData[key] = FormValue.OptionValue(props.design.selected ?: DesignSystem.Option.None)

    if (props.design.visible) {
        bFormLabel {
            htmlFor = props.design.title ?: ""
            className = className(
                listOfNotNull(
                    "d8-subhead d8-marine ",
                    props.classesLabel
                )
            )
            +props.design.title.let {
                it ?: ""
            }
        }
        bRow {
            className = className("row-cols-2 row-cols-md-2 row-cols-lg-3 mb-0 mb-md-1")
            props.design.options.forEach { option ->
                option.movieValue?.let { source ->
                    div {
                        className = className("px-3 d-flex align-items-stretch")
                        div {
                            onClick = {
                                formData[key] = FormValue.OptionValue(option)
                                MainScope().launch { props.onChange(option) }
                            }
                            className = className("position-relative")

                            D8RawMovie { movie = leftOf(source); className = "img-fluid radius-25" }

                            if (props.design.selected?.data == option.data) {
                                div {
                                    className = className("position-absolute n-checked")
                                    img {
                                        className("d-block mx-auto")
                                        src = Design.image(DesignSystem.Image.CHECKED)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.d8MovieInput(
    design: DesignSystem.MovieInput,
    classNameInput: String = "",
    classNameLabel: String = "",
    onChange: suspend ((DesignSystem.Option) -> Unit)
) = D8MovieInput {
    this.classesInput = classNameInput
    this.classesLabel = classNameLabel
    this.design = design
    this.onChange = onChange
}


external interface D8ImageInputProps : PropsWithChildren {
    var classesInput: String
    var classesLabel: String
    var className: String
    var design: DesignSystem.ImageInput
    var onClick: suspend (DesignSystem.Header?, DesignSystem.Option) -> Unit
}

val D8ImageInput = FC<D8ImageInputProps>("D8ImageInput") { props ->
    val formData = useContext(FormContext)
    val key = props.design.header?.id ?: props.design.title ?: ""
    formData[key] = FormValue.OptionValue(props.design.selected ?: DesignSystem.Option.None)
    val imagePosition = if (props.className.isNotBlank()) props.className else "row-cols-lg-4"
    val position = if (props.design.options.size < 4) "justify-content-center" else "justify-content-start"

    if (props.design.visible) {
        bRow {
            className = className("row-cols-1 row-cols-md-2 mb-0 mb-md-1 $position", imagePosition)
            /*div {
                className = className("d-flex justify-content-between")*/

            props.design.options.forEach { option ->
                option.imageValue?.let { img ->
                    Design.image(img)?.let { imgSrc ->
                        div {
                            className = className("d-flex justify-content-center")
                            div {
                                onClick = {
                                    formData.put(key, FormValue.OptionValue(option))
                                    MainScope().launch { props.onClick(props.design.header, option) }
                                }
                                className = className("position-relative text-center")

                                img {
                                    className = className("radius-25")
                                    src = imgSrc
                                    alt = imgSrc
                                }
                                bFormLabel {
                                    htmlFor = props.design.title ?: ""
                                    className = className("d8-subhead d8-marine d-block", props.classesLabel)
                                    +option.title.let {
                                        it ?: ""
                                    }
                                }
                                if (props.design.selected == option) {
                                    val checked = if (option.title.isNullOrEmpty()) "n-checked-type" else "n-checked-type-width-label"
                                    div {
                                        className = className("position-absolute", checked)
                                        img {
                                            className = className("d-block mx-auto")
                                            src = Design.image(DesignSystem.Image.CHECKED)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.d8ImageInput(
    design: DesignSystem.ImageInput,
    classNameInput: String = "",
    classNameLabel: String = "",
    className: String = "",
    onClick: suspend (DesignSystem.Header?, DesignSystem.Option) -> Unit
) = D8ImageInput {
    this.classesInput = classNameInput
    this.classesLabel = classNameLabel
    this.className = className
    this.design = design
    this.onClick = onClick
}

external interface D8RadioInputProps : PropsWithChildren {
    var classNameInput: String
    var classNameLabel: String
    var design: DesignSystem.RadioInput
    var onClick: suspend ((String) -> Unit)
}

val D8RadioInput = FC<D8RadioInputProps>("D8RadioInput") { props ->
    val formData = useContext(FormContext)
    val key = props.design.header?.id ?: props.design.title ?: ""
    formData[key] = FormValue.StringValue(props.design.selected?.title ?: "")

    if (props.design.visible) {
        bFormLabel {
            htmlFor = props.design.title ?: ""
            className = className(
                listOfNotNull(
                    props.classNameLabel,
                    Della8Theme.typography.subhead,
                )
            )
            +props.design.title.let {
                it ?: ""
            }
        }
        props.design.buttons.map { button ->
            div() {
                D8RadioButton {
                    className = props.classNameInput
                    design = button
                    onClick = {
                        formData.put(key, FormValue.StringValue(button.title ?: ""))
                        MainScope().launch { props.onClick(button.title ?: "") }
                    }
                    selected = props.design.selected?.title == button.title
                }
            }
        }
        props.design.status?.message?.let {
            bFormControlFeedback {
                type = it
                className = className(listOfNotNull(props.classNameLabel, Della8Colors.designColor(DesignSystem.Color.RUBY)))
                +it
            }
        }
    }
}

fun ChildrenBuilder.d8RadioInput(
    design: DesignSystem.RadioInput,
    classNameInput: String = "",
    classNameLabel: String = "",
    onClick: suspend ((String) -> Unit)
) = D8RadioInput {
    this.classNameInput = classNameInput
    this.classNameLabel = classNameLabel
    this.design = design
    this.onClick = onClick
}

external interface D8SelectInputProps : PropsWithChildren {
    var classNameInput: String
    var classNameLabel: String
    var design: DesignSystem.SelectInput
    var overrideValue: DesignSystem.Option?
    var onClick: suspend ((DesignSystem.Option) -> Unit)
}

val D8SelectInput = FC<D8SelectInputProps>("D8SelectInput") { props ->
    val formData = useContext(FormContext)
    val key = props.design.header.id
    formData[key] = FormValue.OptionValue(props.design.selected ?: DesignSystem.Option.None)
    val selected = createRef<HTMLSelectElement>()
    val reactScope by useReactScope()

    // TODO: Remove this code or rewrite it. It makes no sense to invoke onClick here. We can't use reactScope since it's useState is not prepared at this point.
    useEffectOnce {
        MainScope().launch {
            props.onClick(props.design.selected ?: DesignSystem.Option.None)
        }
    }

    fun onChange(event: Event) {
        val option = props.design.options.firstOrNull { it.value == selected.current?.value }
        option?.let {
            formData.put(key, FormValue.OptionValue(it))
            reactScope.launch {
                props.onClick(it)
            }
        }
    }

    fun onSelect(eventKey: Any, event: Event) {
        val option = props.design.options.firstOrNull { it.value == eventKey }
        option?.let {
            formData.put(key, FormValue.OptionValue(it))
            reactScope.launch {
                props.onClick(it)
            }
        }
    }

    fun onSelect(option: DesignSystem.Option) {
        formData.put(key, FormValue.OptionValue(option))
        reactScope.launch {
            props.onClick(option)
        }
    }

    if (props.design.visible) {
        when (props.design.style) {
            DesignSystem.SelectStyle.SWITCH -> {
                bNav {
                    variant = "pills"
                    defaultActiveKey = props.design.selected?.value ?: props.design.options.firstOrNull { !it.isDisabled }?.value ?: props.design.options.firstOrNull()?.value ?: ""
                    className = className("justify-content-center")
                    onSelect = ::onSelect
                    bNavItem {
                        className = className("d-flex justify-content-center radius-25 nav-pills", Della8Colors.snow.background)
                        props.design.options.map { option ->
                            bNavLink {
                                className = className("radius-25 px-5")
                                eventKey = option.value
                                disabled = option.isDisabled
                                if (!option.isDisabled) {
                                    option.imageValue?.let {
                                        img {
                                            className = className("px-1")
                                            src = Design.image(it)
                                        }
                                    }
                                }
                                +"${option.title}"
                            }
                        }
                    }
                }
            }

            DesignSystem.SelectStyle.DROP_UP -> {
                bDropdownButton {
                    className = className(props.classNameInput, Della8Colors.snow.background, Della8Colors.sea)
                    this.key = props.design.header.id
                    drop = "up"
                    variant = "secondary"
                    title = props.design.title ?: ""
                    props.design.options.map { option ->
                        bDropdownItem {
                            onClick = { onSelect(option) }
                            p { +"${option.title}" }
                        }
                    }
                }
            }

            DesignSystem.SelectStyle.DROP_DOWN -> {
                bDropdownButton {
                    className = className(props.classNameInput, Della8Colors.snow.background, Della8Colors.sea)
                    this.key = props.design.header.id
                    drop = "down"
                    variant = "secondary"
                    title = props.design.title ?: ""
                    props.design.options.map { option ->
                        bDropdownItem {
                            onClick = { onSelect(option) }
                            p { +"${option.title}" }
                        }
                    }
                }
            }

            DesignSystem.SelectStyle.REGULAR -> {
                bFormGroup {
                    className = className("mb-0")
                    if (props.design.title?.isNotEmpty() == true) {
                        bFormLabel {
                            htmlFor = props.design.title ?: ""
                            className = className(props.classNameLabel)
                            +props.design.title.let {
                                it ?: ""
                            }
                        }
                    }
                    bFormSelect {
                        // className = className(props.classNameInput)
                        this.key = props.design.header.id
                        onChange = ::onChange
                        defaultValue = (props.overrideValue ?: props.design.selected ?: props.design.options.firstOrNull())?.value ?: ""
                        // disabled = props.design.disabled
                        ref = selected

                        props.design.options.map { option ->
                            option {
                                value = option.value
                                disabled = option.isDisabled
                                +"${option.title}"
                            }
                        }
                    }

                    props.design.status?.message?.let {
                        bFormControlFeedback {
                            type = "invalid"
                            className = className(Della8Colors.designColor(DesignSystem.Color.RUBY), "d-block")
                            +it
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.d8SelectInput(
    design: DesignSystem.SelectInput,
    classNameInput: String = "",
    classNameLabel: String = "",
    overrideValue: DesignSystem.Option? = null,
    onClick: suspend ((DesignSystem.Option) -> Unit)
) = D8SelectInput {
    this.classNameInput = classNameInput
    this.classNameLabel = classNameLabel
    this.design = design
    this.overrideValue = overrideValue
    this.onClick = onClick
}


external interface D8SliderInputProps : PropsWithChildren {
    var classNameInput: String
    var classNameLabel: String
    var design: DesignSystem.SliderInput
    var overrideValueInt: Int?
    var onChangeInt: suspend ((DesignSystem.Header, Pair<String, Int?>) -> Unit)
}

val D8SliderInput = FC<D8SliderInputProps>("D8RangeInput") { props ->
    var inputValue: Int? by useState(props.overrideValueInt ?: props.design.selected)
    val reactScope by useReactScope()
    val (_, startTransaction) = useTransition()

    val hasInput = props.design.style == DesignSystem.SliderInputStyle.INPUT_ONLY || props.design.style == DesignSystem.SliderInputStyle.INPUT_AND_SLIDER
    val hasSlider = props.design.style == DesignSystem.SliderInputStyle.SLIDER_ONLY || props.design.style == DesignSystem.SliderInputStyle.INPUT_AND_SLIDER

    useEffect(props.design.selected) {
        inputValue = props.design.selected
    }

    fun onChange(event: Event) {
        (event.target as? HTMLInputElement)?.let { target ->
            val parsedValue = parseDecimal(target.value)
            val intValue = parsedValue.second?.toInt()
            val minValue = props.design.min
            val maxValue = props.design.max
            val updatedValue = when {
                parsedValue.first.isEmpty() -> null
                intValue != null && intValue >= minValue && intValue <= maxValue -> intValue
                else -> inputValue
            }
            inputValue = updatedValue
            techla_log("SLIDER: updated='$updatedValue', current='$inputValue', parsed='${parsedValue.second}', text='${parsedValue.first}', min='$minValue', max='$maxValue'")
            // Use React low priority
            startTransaction {
                reactScope.launch {
                    props.onChangeInt(props.design.header, parsedValue.first to updatedValue)
                }
            }
        }
    }

    if (props.design.visible) {

        bRow {
            className = className("mb-3")
            bCol {
                className = className("pt-2")
                xs = 6; md = 6
                bFormLabel {
                    htmlFor = props.design.header.id
                    className = className(listOf("d8-snow", props.classNameLabel))
                    +props.design.title.let {
                        it ?: ""
                    }
                }

            }
            bCol {
                className = className("")
                xs = 6; md = 6
                bInputGroup {
                    className = className("d-flex align-items-end")
                    bFormControl {
                        className = className(props.classNameInput)
                        type = "number"
                        min = props.design.min
                        max = props.design.max
                        step = props.design.step
                        value = inputValue ?: ""
                        disabled = props.design.disabled
                        this.onChange = ::onChange
                    }

                    props.design.unit?.let {
                        bInputGroupText {
                            +it
                        }
                    }
                }
            }
        }

        if (hasSlider) {
            bFormRange {
                min = props.design.min
                max = props.design.max
                step = props.design.step
                value = inputValue ?: 0
                disabled = props.design.disabled
                onChange = ::onChange
            }
        }

        props.design.status?.message?.let {
            bFormControlFeedback {
                type = it
                className = className(listOfNotNull(props.classNameLabel, Della8Colors.designColor(DesignSystem.Color.RUBY)))
                +it
            }
        }
    }
}

fun ChildrenBuilder.d8SliderInput(
    design: DesignSystem.SliderInput,
    classNameInput: String = "",
    classNameLabel: String = "",
    overrideValueInt: Int? = null,
    onChangeInt: suspend ((DesignSystem.Header, Pair<String, Int?>) -> Unit) = { _, _ -> }
) = D8SliderInput {
    this.classNameInput = classNameInput
    this.classNameLabel = classNameLabel
    this.design = design
    this.overrideValueInt = overrideValueInt
    this.onChangeInt = onChangeInt
}


