package della8.web.components

import bootstrap.*
import della8.core.support.DesignSystem
import della8.web.support.className
import della8.web.support.externalNavigation
import della8.web.theme.Della8Theme
import della8.web.theme.Design
import kotlinx.browser.window
import react.*
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.router.dom.*
import react.router.useNavigate
import techla.base.techla_log
import techla.base.tupleOf

external interface D8NavigationProps : PropsWithChildren {
    var design: DesignSystem.Navigation
    var onClick: (DesignSystem.Action) -> Unit
    var className: String?
}


external interface D8NavigationPrivateProps : PropsWithChildren {
    var design: DesignSystem.Navigation
    var onOptionClick: (DesignSystem.Option) -> Unit
    var onButtonClick: (DesignSystem.Button) -> Unit
    var className: String?
}


val D8Navigation = FC<D8NavigationProps>("D8Navigation") { props ->
    val navigate = useNavigate()

    fun onOptionClick(option: DesignSystem.Option) {
        window.scrollTo(x = 0.0, y = 0.0)
        techla_log("NAVIGATION: Option click with location='${option.locationValue}', action='${option.actionValue}'")
        when {
            option.locationValue != null -> navigate(option.locationValue!!.route)
            option.actionValue != null -> props.onClick(option.actionValue!!)
        }
    }

    fun onButtonClick(button: DesignSystem.Button) {
        window.scrollTo(x = 0.0, y = 0.0)
        techla_log("NAVIGATION: Button click with location='${button.location}', action='${button.action}'")
        when {
            button.location != null -> navigate(button.location!!.route)
            button.action != null -> props.onClick(button.action!!)
        }
    }

    // Background
    div {
        className = className("n-bg-container")

        when (props.design.background) {
            DesignSystem.Background.DARK ->
                if (props.design.movie != null) {
                    D8Movie { design = props.design.movie!!; className = "n-bg full" }
                } else {
                    img {
                        className = className("n-bg full")
                        src = Design.image(DesignSystem.Image.BACKGROUND)
                        alt = Design.imageAltText(DesignSystem.Image.BACKGROUND)
                    }
                }

            else -> {
                div {
                    className = className("n-bg full bg-color")
                }
            }
        }
    }

    if (props.design.menu != null)
        D8CollapsableNavigation {
            design = props.design
            onOptionClick = ::onOptionClick
            onButtonClick = ::onButtonClick
            className = props.className
        }
    else
        D8SimpleNavigation {
            design = props.design
            onOptionClick = ::onOptionClick
            onButtonClick = ::onButtonClick
            className = props.className
        }
}


val D8SimpleNavigation = FC<D8NavigationPrivateProps>("D8SimpleNavigation") { props ->
    val (color, logo, back) = when (props.design.background) {
        DesignSystem.Background.DARK ->
            tupleOf(Della8Theme.colors.snow, DesignSystem.Image.LOGO, DesignSystem.Image.BACK_WHITE)

        DesignSystem.Background.LIGHT ->
            tupleOf(Della8Theme.colors.marine, DesignSystem.Image.LOGO_LIGHT, DesignSystem.Image.BACK)
    }

    bContainer {
        fluid = true
        className = className("py-4 py-lg-5 px-3 px-md-5 position-absolute n-menu-zindex", props.className)

        props.design.navigation?.let { option ->
            bButton {
                variant = "none"
                className = className("position-absolute", color)
                onClick = { _ -> props.onOptionClick(option) }
                Design.image(back)?.let {
                    img { src = it; alt = Design.imageAltText(back) }
                }
                +(option.title ?: "")
            }
        }

        bContainer {
            className = className("m-0 p-0 mx-auto d-flex justify-content-center d8-back-menu-logo")

            NavLink {
                to = props.design.location?.route ?: ""

                Design.image(logo)?.let {
                    img {
                        className = className("n-logo")
                        src = it
                        alt = Design.imageAltText(logo)
                    }
                }
            }
        }
    }

    bNav {
        className = className("d-flex  justify-content-center fixed-bottom n-tabbar")
        props.design.objectButtons?.map { button ->
            if (button.visible) {
                div {
                    className = className(if (button.selected) "n-tabbar-active" else "n-tabbar-inactive")
                    d8Button(className = "px-3 py-2 d-block mx-auto n-tabbar-button", design = button) {
                        props.onButtonClick(button)
                    }
                }
            }
        }
    }
}


val D8CollapsableNavigation = FC<D8NavigationPrivateProps>("D8CollapsableNavigation") { props ->
    val (color, logo) = when (props.design.background) {
        DesignSystem.Background.DARK ->
            tupleOf(Della8Theme.colors.snow, DesignSystem.Image.LOGO)

        DesignSystem.Background.LIGHT ->
            tupleOf(Della8Theme.colors.marine, DesignSystem.Image.LOGO_LIGHT)
    }

    bContainer {
        fluid = true
        className = className("py-5 px-3 px-md-5 position-absolute n-menu-zindex", props.className)
        bNavbar {
            className = className("p-0 ")

            expand = "lg"; variant = "dark"

            bNavbarBrand {
                className = className("n-logo-cont m-0 p-0")

                NavLink {
                    to = props.design.location?.route ?: ""
                    Design.image(logo)?.let {
                        img {
                            className = className("n-logo")
                            src = it
                            alt = Design.imageAltText(logo)
                        }
                    }
                }
            }

            /*Hide in desktop*/
            props.design.navigationButtons?.forEach { button ->
                d8Button(className = "ms-auto d-block d-lg-none", design = button) {
                    props.onButtonClick(button)
                }
            }

            bNavbarToggle {
                className = className("custom-toggler ms-2")
            }

            props.design.menu?.let { menu ->
                bNavbarCollapse {
                    className = className("justify-content-end d8-menu-mobile")

                    bNav {
                        className = className("p-4 p-lg-0", color)
                        onSelect = { eventKey, _ ->
                            menu.items.firstOrNull { it.value == eventKey }?.let {
                                props.onOptionClick(it)
                            }
                        }
                        menu.items.forEach { option ->
                            bNavItem {
                                if (option.actionValue === DesignSystem.Action.CONTACT) {
                                    a {
                                        target = AnchorTarget._blank
                                        href = externalNavigation(option.actionValue)
                                        className = className("menu nav-link", color)
                                        id = color

                                        Design.image(option.imageValue)?.let {
                                            img { src = it; alt = Design.imageAltText(option.imageValue) }
                                        }
                                        +(option.title ?: "")
                                    }
                                } else {
                                    bNavLink {
                                        className = className("menu", color, if (menu.selected == option) "n-nav-active" else "n-tabbar-inactive")

                                        if (option.value == null)
                                            techla_log("NAVIGATION: Warning, options in menus needs a value")
                                        eventKey = option.value
                                        id = color
                                        Design.image(option.imageValue)?.let {
                                            img { src = it; alt = Design.imageAltText(option.imageValue) }
                                        }
                                        +(option.title ?: "")
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /*Hide in mobile*/
            props.design.navigationButtons?.forEach { button ->
                d8Button(className = "ms-auto d-none d-lg-block", design = button) {
                    props.onButtonClick(button)
                }
            }
        }
    }
}


fun ChildrenBuilder.d8Navigation(
    design: DesignSystem.Navigation,
    onClick: (DesignSystem.Action) -> Unit = {},
    className: String? = ""
) =
    D8Navigation {
        this.design = design
        this.onClick = onClick
        this.className = className
    }
