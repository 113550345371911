package della8.core.services

import della8.core.support.ActionOutcome
import della8.core.support.Store
import techla.base.*
import techla.control.ControlAPI
import techla.control.ControlAPIResource
import techla.control.Event
import techla.guard.Application

suspend fun Store.findStatistics(actions: List<Event.Action>, categories: List<Event.Category>, intervals: List<Event.Interval>, timeZone: String, locale: String, application: Key<Application>): ActionOutcome<List<Event.Statistic>> {
    return withUserToken { updated ->
        val api = updated.controlAPI
        measureAPI(ControlAPIResource.FindStatistics(actions = actions, categories = categories, intervals = intervals, timeZone = timeZone, locale = locale, application = application, subcategories = null, uniqueOnly = false), api) {
            api.findStatistics(actions = actions, categories = categories, intervals = intervals, timeZone = timeZone, locale = locale, application = application)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}


val Store.controlAPI
    get() =
        ControlAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) ControlAPI.sandbox else ControlAPI.shared
            api.token = adminToken
        }
