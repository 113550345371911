package della8.core.services

import della8.core.support.*
import techla.base.*
import techla.reservation.*
import techla.reservation.Resource

suspend fun Store.listResources() =
    ReservationEndpoint.listResources(this)


suspend fun Store.listResources(obj: Object) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        ReservationEndpoint.listResources(reduce(action))
    }


suspend fun Store.createResource(create: Resource.Create) =
    ReservationEndpoint.createResource(this, create)


suspend fun Store.createResource(obj: Object, create: Resource.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createResource(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }


suspend fun Store.editResource(id: Identifier<Resource>, edit: Resource.Edit) =
    ReservationEndpoint.editResource(this, id, edit)


suspend fun Store.editResource(obj: Object, id: Identifier<Resource>, edit: Resource.Edit) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).editResource(id, edit)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }


suspend fun Store.deleteResource(id: Identifier<Resource>) =
    ReservationEndpoint.deleteResource(this, id)


suspend fun Store.deleteResource(obj: Object, id: Identifier<Resource>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).deleteResource(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }


private suspend fun Store.getReservation(id: Identifier<Reservation>) =
    ReservationEndpoint.getReservation(this, id)


suspend fun Store.getReservation(obj: Object, id: Identifier<Reservation>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getReservation(id)
    }


private suspend fun Store.createReservation(create: Reservation.Create) =
    ReservationEndpoint.createReservation(this, create)


suspend fun Store.createReservation(obj: Object, create: Reservation.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createReservation(create)
    }


private suspend fun Store.deleteReservation(id: Identifier<Reservation>) =
    ReservationEndpoint.deleteReservation(this, id)


suspend fun Store.deleteReservation(obj: Object, id: Identifier<Reservation>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).deleteReservation(id)
    }


private suspend fun Store.getReservations(ids: List<Identifier<Reservation>>) =
    successfulOf(ids)
        .mapEach { id ->
            ReservationEndpoint.getReservation(this, id)
        }
        .flatMap { list ->
            val actions = list.flatMap { it.outputOrNull()?.first ?: emptyList() }
            val reservations = list.mapNotNull { it.outputOrNull()?.second }
            val exceptions = list.mapNotNull { it.exceptionOrNull() }
            val warnings = list.flatMap { it.warningsOrNull() ?: emptyList() }
            when {
                exceptions.isNotEmpty() -> failedOf(exceptions.first())
                warnings.isNotEmpty() -> invalidOf(warnings)
                else -> successfulOf(actions to reservations)
            }
        }


suspend fun Store.getReservations(obj: Object, ids: List<Identifier<Reservation>>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getReservations(ids)
    }


suspend fun Store.findAvailability(resource: Key<Resource>, filter: Reservation.Filter, style: Reservation.Style?) =
    ReservationEndpoint.findAvailability(this, resource, filter, style)


suspend fun Store.findAvailability(resource: Key<Resource>, filters: List<Reservation.Filter>, style: Reservation.Style?) =
    filters
        .map { findAvailability(resource, it, style) }
        .all()
        .map { all ->
            tupleOf(all.flatMap { it.first }, all.flatMap { it.second })
        }


suspend fun Store.findAvailability(obj: Object, resource: Key<Resource>, filters: List<Reservation.Filter>, style: Reservation.Style?) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).findAvailability(resource, filters, style)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }


val Store.reservationAPI
    get() =
        ReservationAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) ReservationAPI.sandbox else ReservationAPI.shared
            api.token = userToken ?: applicationToken
        }

object ReservationEndpoint {
    var listResources: suspend (store: Store) -> ActionOutcome<List<Resource>> = { store ->
        if (store.demoMode)
            Demo.listAllResources.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.reservationAPI
                measureAPI(ReservationAPIResource.ListResources, api) {
                    api.listResources()
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var createResource: suspend (store: Store, create: Resource.Create) -> ActionOutcome<Resource> = { store, create ->
        if (store.demoMode)
            Demo.resource.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.reservationAPI
                measureAPI(ReservationAPIResource.CreateResource(create), api) {
                    api.createResource(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var editResource: suspend (store: Store, id: Identifier<Resource>, edit: Resource.Edit) -> ActionOutcome<Resource> = { store, id, edit ->
        if (store.demoMode)
            Demo.resource.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.reservationAPI
                measureAPI(ReservationAPIResource.EditResource(id, edit), api) {
                    api.editResource(id, edit)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var deleteResource: suspend (store: Store, id: Identifier<Resource>) -> ActionOutcome<Unit> = { store, id ->
        if (store.demoMode) successfulOf(Unit).noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.reservationAPI
                measureAPI(ReservationAPIResource.DeleteResource(id), api) {
                    api.deleteResource(id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var getReservation: suspend (store: Store, id: Identifier<Reservation>) -> ActionOutcome<Reservation> = { store, id ->
        if (store.demoMode)
            Demo.reservation.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.reservationAPI
                measureAPI(ReservationAPIResource.GetReservation(id), api) {
                    api.getReservation(id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var createReservation: suspend (store: Store, create: Reservation.Create) -> ActionOutcome<Reservation> = { store, create ->
        if (store.demoMode)
            Demo.reservation.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.reservationAPI
                measureAPI(ReservationAPIResource.CreateReservation(create), api) {
                    api.createReservation(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var deleteReservation: suspend (store: Store, id: Identifier<Reservation>) -> ActionOutcome<Unit> = { store, id ->
        if (store.demoMode)
            successfulOf(Unit).noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.reservationAPI
                measureAPI(ReservationAPIResource.DeleteReservation(id), api) {
                    api.deleteReservation(id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var findAvailability: suspend (store: Store, resource: Key<Resource>, filter: Reservation.Filter, style: Reservation.Style?) -> ActionOutcome<List<Availability>> = { store, resource, filter, style ->
        if (store.demoMode)
            Demo.listAllAvailability.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.reservationAPI
                measureAPI(ReservationAPIResource.FindAvailability(resource, filter, style), api) {
                    api.findAvailability(resource, filter, style)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }
}