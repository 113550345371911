package della8.core.services

import della8.core.support.ActionOutcome
import della8.core.support.Object
import della8.core.support.Store
import della8.core.support.accumulate
import techla.base.*
import techla.storage.Asset
import techla.storage.StorageAPI
import techla.storage.StorageAPIResource


private suspend fun Store.createAsset(create: Asset.Create) =
    StorageEndpoint.createAsset(this, create)

suspend fun Store.createAsset(obj: Object, create: Asset.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createAsset(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.deleteAsset(id: Identifier<Asset>) =
    StorageEndpoint.deleteAsset(this, id)

suspend fun Store.deleteAsset(obj: Object, id: Identifier<Asset>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).deleteAsset(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.getAsset(id: Identifier<Asset>) =
    StorageEndpoint.getAsset(this, id)

suspend fun Store.getAsset(obj: Object, id: Identifier<Asset>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getAsset(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.getAssets(batch: Asset.Batch) =
    StorageEndpoint.getAssets(this, batch)

suspend fun Store.getAssets(obj: Object, batch: Asset.Batch) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getAssets(batch)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

val Store.storageApi
    get() =
        StorageAPI(httpClient).also { api ->
            api.token = userToken ?: applicationToken
            api.host = if (deployment.isSandbox) StorageAPI.sandbox else StorageAPI.shared
        }

object StorageEndpoint {
    var createAsset: suspend (store: Store, create: Asset.Create) -> ActionOutcome<Asset> = { store, create ->
        store.withUserToken { updated ->
            val api = updated.storageApi
            measureAPI(StorageAPIResource.CreateAsset(create), api) {
                api.createAsset(create)
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }

    var deleteAsset: suspend (store: Store, id: Identifier<Asset>) -> ActionOutcome<Unit> = { store, id ->
        store.withUserToken { updated ->
            val api = updated.storageApi
            measureAPI(StorageAPIResource.DeleteAsset(id), api) {
                api.deleteAsset(id)
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }

    var getAsset: suspend (store: Store, id: Identifier<Asset>) -> ActionOutcome<Asset> = { store, id ->
        store.withUserToken { updated ->
            val api = updated.storageApi
            measureAPI(StorageAPIResource.GetAsset(id), api) {
                api.getAsset(id)
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }

    var getAssets: suspend (store: Store, batch: Asset.Batch) -> ActionOutcome<List<Asset>> = { store, batch ->
        store.withUserToken { updated ->
            val api = updated.storageApi
            measureAPI(StorageAPIResource.GetAssets(batch), api) {
                api.getAssets(batch)
                    .onNotSuccess { techla_log("WARN: $it") }
            }
        }
    }
}