package della8.web.theme

import della8.core.support.DesignSystem

@JsModule("./svg/logo_della8_article.svg")
@JsNonModule
external val articleLogo: dynamic

@JsModule("./img/bg_della8.jpg")
@JsNonModule
external val background: dynamic

@JsModule("./svg/logo_della8.svg")
@JsNonModule
external val logo: dynamic

@JsModule("./svg/logo_della8_light.svg")
@JsNonModule
external val logo_light: dynamic

@JsModule("./svg/ico_bankid.svg")
@JsNonModule
external val bankid: dynamic

@JsModule("./svg/ico_block_book.svg")
@JsNonModule
external val blockBooking: dynamic

@JsModule("./svg/ico_block_aprove.svg")
@JsNonModule
external val blockAppropve: dynamic

@JsModule("./svg/ico_block_connected.svg")
@JsNonModule
external val blockConnected: dynamic

@JsModule("./svg/ico_block_invite.svg")
@JsNonModule
external val blockInvite: dynamic

@JsModule("./svg/ico_block_ownership.svg")
@JsNonModule
external val blockOwnership: dynamic


@JsModule("./svg/ico_checked.svg")
@JsNonModule
external val checked: dynamic

@JsModule("./svg/ico_check.svg")
@JsNonModule
external val check: dynamic

@JsModule("./svg/ico_invite.svg")
@JsNonModule
external val invite: dynamic

@JsModule("./svg/ico_start.svg")
@JsNonModule
external val start: dynamic

@JsModule("./svg/ico_settings.svg")
@JsNonModule
external val settings: dynamic

@JsModule("./svg/ico_shop.svg")
@JsNonModule
external val shop: dynamic

@JsModule("./svg/ico_code.svg")
@JsNonModule
external val code: dynamic

@JsModule("./svg/ico_invites.svg")
@JsNonModule
external val invites: dynamic

@JsModule("./svg/ico_copy.svg")
@JsNonModule
external val copy: dynamic

@JsModule("./svg/ico_contract.svg")
@JsNonModule
external val agreement: dynamic

@JsModule("./svg/ico_contract_updates.svg")
@JsNonModule
external val agreement_updates: dynamic

@JsModule("./svg/ico_contract_sm.svg")
@JsNonModule
external val agreement_sm: dynamic

@JsModule("./svg/ico_no_contract.svg")
@JsNonModule
external val noagreement: dynamic

@JsModule("./svg/ico_booking_others.svg")
@JsNonModule
external val bookedOther: dynamic

@JsModule("./svg/ico_booking_you.svg")
@JsNonModule
external val bookedMe: dynamic

@JsModule("./svg/ico_booking_locked.svg")
@JsNonModule
external val locked: dynamic

@JsModule("./svg/ico_booking_common.svg")
@JsNonModule
external val common: dynamic

@JsModule("./svg/ico_booking_rulebased.svg")
@JsNonModule
external val rulebased: dynamic

@JsModule("./svg/ico_previous.svg")
@JsNonModule
external val previous: dynamic

@JsModule("./svg/ico_next.svg")
@JsNonModule
external val next: dynamic

@JsModule("./svg/ico_back.svg")
@JsNonModule
external val back: dynamic

@JsModule("./svg/ico_back_white.svg")
@JsNonModule
external val backWhite: dynamic

@JsModule("./svg/ico_history.svg")
@JsNonModule
external val version: dynamic

@JsModule("./svg/ico_pdf.svg")
@JsNonModule
external val download: dynamic

@JsModule("./svg/ico_info.svg")
@JsNonModule
external val info: dynamic

@JsModule("./svg/ico_rules.svg")
@JsNonModule
external val rules: dynamic

@JsModule("./svg/ico_booking_calendar.svg")
@JsNonModule
external val calendar: dynamic

@JsModule("./svg/ico_booking_gold.svg")
@JsNonModule
external val gold: dynamic

@JsModule("./svg/ico_trash.svg")
@JsNonModule
external val trash: dynamic

@JsModule("./svg/ico_related.svg")
@JsNonModule
external val related: dynamic

@JsModule("./svg/ico_crown.svg")
@JsNonModule
external val crown: dynamic

@JsModule("./svg/ico_gamerules.svg")
@JsNonModule
external val gameRules: dynamic

@JsModule("./svg/ico_success.svg")
@JsNonModule
external val happiness: dynamic

@JsModule("./svg/ico_copied.svg")
@JsNonModule
external val copied: dynamic

@JsModule("./svg/ico_failure.svg")
@JsNonModule
external val failure: dynamic

@JsModule("./svg/ico_linkedin.svg")
@JsNonModule
external val linkedin: dynamic

@JsModule("./svg/ico_instagram.svg")
@JsNonModule
external val instagram: dynamic

@JsModule("./svg/ico_arrow_scroll_down.svg")
@JsNonModule
external val arrowScrollDown: dynamic

@JsModule("./svg/ico_launch.svg")
@JsNonModule
external val launch: dynamic

@JsModule("./svg/ico_booking_gold.svg")
@JsNonModule
external val booking_gold: dynamic

@JsModule("./svg/ico_booking_lock.svg")
@JsNonModule
external val booking_lock: dynamic

@JsModule("./svg/ico_booking_today.svg")
@JsNonModule
external val booking_today: dynamic

@JsModule("./svg/ico_tip.svg")
@JsNonModule
external val tip: dynamic

@JsModule("./svg/ico_arrival.svg")
@JsNonModule
external val arrival: dynamic

@JsModule("./svg/ico_departure.svg")
@JsNonModule
external val departure: dynamic


@JsModule("./svg/ico_banner_testpilots.svg")
@JsNonModule
external val bannerTestpilots: dynamic

@JsModule("./svg/ico_booking_gold_locked.svg")
@JsNonModule
external val booking_gold_locked: dynamic

@JsModule("./svg/ico_rule_on_btn.svg")
@JsNonModule
external val rule_on_btn: dynamic

@JsModule("./svg/ico_rule_off_btn.svg")
@JsNonModule
external val rule_off_btn: dynamic

@JsModule("./svg/ico_rule_on_black.svg")
@JsNonModule
external val rule_on_black: dynamic

@JsModule("./svg/ico_rule_off_black.svg")
@JsNonModule
external val rule_off_black: dynamic

@JsModule("./svg/ico_updates.svg")
@JsNonModule
external val updates: dynamic

@JsModule("./svg/ico_info_highlight.svg")
@JsNonModule
external val info_highlight: dynamic

@JsModule("./svg/ico_contract_draft.svg")
@JsNonModule
external val contract_draft: dynamic

@JsModule("./svg/ico_step_1.svg")
@JsNonModule
external val step_1: dynamic

@JsModule("./svg/ico_step_2.svg")
@JsNonModule
external val step_2: dynamic

@JsModule("./svg/ico_step_3.svg")
@JsNonModule
external val step_3: dynamic

@JsModule("./svg/ico_step_4.svg")
@JsNonModule
external val step_4: dynamic

@JsModule("./svg/ico_step_5.svg")
@JsNonModule
external val step_5: dynamic

@JsModule("./svg/ico_step_6.svg")
@JsNonModule
external val step_6: dynamic

@JsModule("./svg/ico_step_7.svg")
@JsNonModule
external val step_7: dynamic

@JsModule("./svg/ico_step_8.svg")
@JsNonModule
external val step_8: dynamic

@JsModule("./svg/ico_step_9.svg")
@JsNonModule
external val step_9: dynamic

@JsModule("./svg/ico_step_10.svg")
@JsNonModule
external val step_10: dynamic

@JsModule("./svg/ico_feed.svg")
@JsNonModule
external val feed: dynamic

@JsModule("./svg/ico_feed_edit.svg")
@JsNonModule
external val feed_edit: dynamic

@JsModule("./svg/ico_feed_like_filled.svg")
@JsNonModule
external val feed_like_filled: dynamic

@JsModule("./svg/ico_feed_like_outlined.svg")
@JsNonModule
external val feed_like_outlined: dynamic

@JsModule("./svg/ico_feed_reactions.svg")
@JsNonModule
external val feed_reactions: dynamic

@JsModule("./svg/ico_feed_updates.svg")
@JsNonModule
external val feed_updates: dynamic

@JsModule("./svg/ico_vote.svg")
@JsNonModule
external val vote: dynamic

@JsModule("./svg/ico_vote_success.svg")
@JsNonModule
external val vote_success: dynamic

@JsModule("./svg/ico_pricehint.svg")
@JsNonModule
external val pricehint: dynamic

@JsModule("./svg/ico_objects_to_coown.svg")
@JsNonModule
external val objects_to_coown: dynamic

@JsModule("./svg/logo_resurs.svg")
@JsNonModule
external val logo_resurs: dynamic

@JsModule("./svg/logo_vinterbyn.svg")
@JsNonModule
external val logo_vinterbyn: dynamic

@JsModule("./img/logo_bengt.png")
@JsNonModule
external val logo_bengt: dynamic

@JsModule("./img/img_objects_own.png")
@JsNonModule
external val objects_own: dynamic

@JsModule("./img/img_resurs.png")
@JsNonModule
external val resurs: dynamic

@JsModule("./img/img_other_object_xl.png")
@JsNonModule
external val other_object_xl: dynamic

@JsModule("./img/img_present_agreement_xl.png")
@JsNonModule
external val present_agreement_xl: dynamic

@JsModule("./img/img_realestate_xl.png")
@JsNonModule
external val realestate_xl: dynamic

@JsModule("./img/img_motorhome_xl.png")
@JsNonModule
external val motorhome_xl: dynamic

@JsModule("./img/img_motorhome.png")
@JsNonModule
external val motorhome: dynamic

@JsModule("./img/img_realestate.png")
@JsNonModule
external val realestate: dynamic

@JsModule("./img/img_boat.png")
@JsNonModule
external val yacht: dynamic

@JsModule("./img/img_no_object.jpg")
@JsNonModule
external val noObject: dynamic

@JsModule("./img/img_other_object.png")
@JsNonModule
external val otherObject: dynamic

@JsModule("./img/img_present_agreement.png")
@JsNonModule
external val presentAgreement: dynamic

@JsModule("./img/img_olivia_talks.png")
@JsNonModule
external val oliviaTalks: dynamic

@JsModule("./img/img_calculator_boat_cheap.jpg")
@JsNonModule
external val calculator_boat_cheap: dynamic

@JsModule("./img/img_calculator_boat_expensive.jpg")
@JsNonModule
external val calculator_boat_expensive: dynamic

@JsModule("./img/img_calculator_holidayhome_expensive.jpg")
@JsNonModule
external val calculator_holidayhome_expensive: dynamic

@JsModule("./img/img_calculator_holidayhome_cheap.jpg")
@JsNonModule
external val calculator_holidayhome_cheap: dynamic

@JsModule("./img/img_calculator_motorhome_cheap.jpg")
@JsNonModule
external val calculator_motorhome_cheap: dynamic

@JsModule("./img/img_calculator_motorhome_expensive.jpg")
@JsNonModule
external val calculator_motorhome_expensive: dynamic

@JsModule("./img/img_calculator_own_settings.jpg")
@JsNonModule
external val calculator_own_settings: dynamic

@JsModule("./img/img_della8_tool.png")
@JsNonModule
external val della8_tool: dynamic

@JsModule("./img/img_why_della8_banner.png")
@JsNonModule
external val why_della8_banner: dynamic

@JsModule("./img/img_mock.png")
@JsNonModule
external val mock: dynamic

@JsModule("./img/img-video-preview.png")
@JsNonModule
external val video_preview: dynamic

@JsModule("./svg/ico_why_della8_price.svg")
@JsNonModule
external val why_della8_price: dynamic

@JsModule("./svg/ico_why_della8_stars.svg")
@JsNonModule
external val why_della8_stars: dynamic

@JsModule("./svg/ico_match.svg")
@JsNonModule
external val match: dynamic

@JsModule("./svg/ico_close.svg")
@JsNonModule
external val close: dynamic

@JsModule("./svg/ico_play.svg")
@JsNonModule
external val play: dynamic

@JsModule("./img/img-action-greeting-della-avatar2.png")
@JsNonModule
external val della_avatar_greeting: dynamic

@JsModule("./img/img-action-happy-della-avatar2.png")
@JsNonModule
external val della_avatar_happy: dynamic

@JsModule("./svg/ico_empty_contract.svg")
@JsNonModule
external val empty_contract: dynamic

@JsModule("./svg/ico-minimize.svg")
@JsNonModule
external val minimize: dynamic

@JsModule("./svg/ico-della-info.svg")
@JsNonModule
external val della_info: dynamic

@JsModule("./lottie/spinner.json")
@JsNonModule
external val spinner: dynamic

@JsModule("./lottie/anim-della-like2.json")
@JsNonModule
external val like2: dynamic

@JsModule("./video/mountains.mp4")
@JsNonModule
external val mountains: dynamic

@JsModule("./video/snow.mp4")
@JsNonModule
external val snow: dynamic

@JsModule("./video/camping.mp4")
@JsNonModule
external val camping: dynamic

@JsModule("./video/pool.mp4")
@JsNonModule
external val pool: dynamic

@JsModule("./video/fireworks.mp4")
@JsNonModule
external val fireworks: dynamic

@JsModule("./video/sea.mp4")
@JsNonModule
external val sea: dynamic

@JsModule("./video/forrest.mp4")
@JsNonModule
external val forrest: dynamic

@JsModule("./video/mountains_small.mp4")
@JsNonModule
external val mountains_xs: dynamic

@JsModule("./video/snow_small.mp4")
@JsNonModule
external val snow_xs: dynamic

@JsModule("./video/camping_small.mp4")
@JsNonModule
external val camping_xs: dynamic

@JsModule("./video/pool_small.mp4")
@JsNonModule
external val pool_xs: dynamic

@JsModule("./video/sea_small.mp4")
@JsNonModule
external val sea_xs: dynamic

@JsModule("./video/forrest_small.mp4")
@JsNonModule
external val forrest_xs: dynamic


object Design {
    fun image(image: DesignSystem.Image?): String? {
        return when (image) {
            DesignSystem.Image.ARTICLE_LOGO -> articleLogo.default
            DesignSystem.Image.ARRIVAL -> arrival.default
            DesignSystem.Image.BACK -> back.default
            DesignSystem.Image.BACK_WHITE -> backWhite.default
            DesignSystem.Image.BACKGROUND -> background.default
            DesignSystem.Image.BANK_ID -> bankid.default
            DesignSystem.Image.CHECKED -> checked.default
            DesignSystem.Image.CHECK -> check.default
            DesignSystem.Image.DEPARTURE -> departure.default
            DesignSystem.Image.INVITE -> invite.default
            DesignSystem.Image.LOGO -> logo.default
            DesignSystem.Image.LOGO_LIGHT -> logo_light.default
            DesignSystem.Image.NEXT -> next.default
            DesignSystem.Image.PREVIOUS -> previous.default
            DesignSystem.Image.SETTINGS -> settings.default
            DesignSystem.Image.SHOP -> shop.default
            DesignSystem.Image.INVITES -> invites.default
            DesignSystem.Image.COPY -> copy.default
            DesignSystem.Image.CODE -> code.default
            DesignSystem.Image.START -> start.default
            DesignSystem.Image.AGREEMENT -> agreement.default
            DesignSystem.Image.AGREEMENT_SM -> agreement_sm.default
            DesignSystem.Image.NO_AGREEMENT -> noagreement.default
            DesignSystem.Image.BOOKED_OTHER -> bookedOther.default
            DesignSystem.Image.BOOKED_ME -> bookedMe.default
            DesignSystem.Image.LOCKED -> locked.default
            DesignSystem.Image.COMMON -> common.default
            DesignSystem.Image.RULEBASED -> rulebased.default
            DesignSystem.Image.VERSION -> version.default
            DesignSystem.Image.DOWNLOAD -> download.default
            DesignSystem.Image.INFO -> info.default
            DesignSystem.Image.RULES -> rules.default
            DesignSystem.Image.CALENDAR -> calendar.default
            DesignSystem.Image.GOLD -> gold.default
            DesignSystem.Image.BLOCK_BOOKING -> blockBooking.default
            DesignSystem.Image.BLOCK_APPROVE -> blockAppropve.default
            DesignSystem.Image.BLOCK_CONNECTED -> blockConnected.default
            DesignSystem.Image.BLOCK_INVITE -> blockInvite.default
            DesignSystem.Image.BLOCK_OWNERSHIP -> blockOwnership.default
            DesignSystem.Image.TRASH -> trash.default
            DesignSystem.Image.RELATED -> related.default
            DesignSystem.Image.CROWN -> crown.default
            DesignSystem.Image.GAME_RULES -> gameRules.default
            DesignSystem.Image.HAPPINESS -> happiness.default
            DesignSystem.Image.COPIED -> copied.default
            DesignSystem.Image.FAILURE -> failure.default
            DesignSystem.Image.ARROWSCROLLDOWN -> arrowScrollDown.default
            DesignSystem.Image.LINKEDIN -> linkedin.default
            DesignSystem.Image.INSTAGRAM -> instagram.default
            DesignSystem.Image.LAUNCH -> launch.default
            DesignSystem.Image.MOTOR_HOME -> motorhome.default
            DesignSystem.Image.HOLIDAY_HOME -> realestate.default
            DesignSystem.Image.BOOKING_GOLD -> booking_gold.default
            DesignSystem.Image.BOOKING_LOCK -> booking_lock.default
            DesignSystem.Image.BOOKING_TODAY -> booking_today.default
            DesignSystem.Image.TIP -> tip.default
            DesignSystem.Image.NOOBJECT -> noObject.default
            DesignSystem.Image.BANNERTESTPILOTS -> bannerTestpilots.default
            DesignSystem.Image.BOOKING_GOLD_LOCKED -> booking_gold_locked.default
            DesignSystem.Image.RULE_ON_BTN -> rule_on_btn.default
            DesignSystem.Image.RULE_OFF_BTN -> rule_off_btn.default
            DesignSystem.Image.RULE_ON_BLACK -> rule_on_black.default
            DesignSystem.Image.RULE_OFF_BLACK -> rule_off_black.default
            DesignSystem.Image.OTHER_OBJECT -> otherObject.default
            DesignSystem.Image.PRESENT_AGREEMENT -> presentAgreement.default
            DesignSystem.Image.OTHER_OBJECT_XL -> other_object_xl.default
            DesignSystem.Image.HOLIDAY_HOME_XL -> realestate_xl.default
            DesignSystem.Image.MOTOR_HOME_XL -> motorhome_xl.default
            DesignSystem.Image.PRESENT_AGREEMENT_XL -> present_agreement_xl.default
            DesignSystem.Image.UPDATES -> updates.default
            DesignSystem.Image.AGREEMENT_UPDATES -> agreement_updates.default
            DesignSystem.Image.HIGHLIGHT -> info_highlight.default
            DesignSystem.Image.CONTRACT_DRAFT -> contract_draft.default
            DesignSystem.Image.STEP1 -> step_1.default
            DesignSystem.Image.STEP2 -> step_2.default
            DesignSystem.Image.STEP3 -> step_3.default
            DesignSystem.Image.STEP4 -> step_4.default
            DesignSystem.Image.STEP5 -> step_5.default
            DesignSystem.Image.STEP6 -> step_6.default
            DesignSystem.Image.STEP7 -> step_7.default
            DesignSystem.Image.STEP8 -> step_8.default
            DesignSystem.Image.STEP9 -> step_9.default
            DesignSystem.Image.STEP10 -> step_10.default
            DesignSystem.Image.FEED -> feed.default
            DesignSystem.Image.FEED_EDIT -> feed_edit.default
            DesignSystem.Image.FEED_LIKE_FILLED -> feed_like_filled.default
            DesignSystem.Image.FEED_LIKE_OUTLINED -> feed_like_outlined.default
            DesignSystem.Image.FEED_REACTIONS -> feed_reactions.default
            DesignSystem.Image.FEED_UPDATES -> feed_updates.default
            DesignSystem.Image.VOTE -> vote.default
            DesignSystem.Image.VOTE_SUCCESS -> vote_success.default
            DesignSystem.Image.CLIPPY -> oliviaTalks.default
            DesignSystem.Image.CALCULATOR_BOAT_CHEAP -> calculator_boat_cheap.default
            DesignSystem.Image.CALCULATOR_BOAT_EXPENSIVE -> calculator_boat_expensive.default
            DesignSystem.Image.CALCULATOR_HOLIDAYHOME_CHEAP -> calculator_holidayhome_cheap.default
            DesignSystem.Image.CALCULATOR_HOLIDAYHOME_EXPENSIVE -> calculator_holidayhome_expensive.default
            DesignSystem.Image.CALCULATOR_MOTORHOME_EXPENSIVE -> calculator_motorhome_expensive.default
            DesignSystem.Image.CALCULATOR_MOTORHOME_CHEAP -> calculator_motorhome_cheap.default
            DesignSystem.Image.CALCULATOR_OWN_SETTINGS -> calculator_own_settings.default
            DesignSystem.Image.DELLA8_EMELIE -> "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/img_della8_emelie.png"
            DesignSystem.Image.DELLA8_JOHANNA -> "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/img_della8_johanna.png"
            DesignSystem.Image.DELLA8_MAGNUS -> "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/img_della8_magnus.png"
            DesignSystem.Image.DELLA8_MICKE -> "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/img_della8_micke.png"
            DesignSystem.Image.DELLA8_OLIVIA -> "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/img_della8_olivia.png"
            DesignSystem.Image.DELLA8_NIKLAS -> "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/img_della8_niklas.png"
            DesignSystem.Image.DELLA8_NAOMI -> "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/img_della8_naomi.png"
            DesignSystem.Image.DELLA_TOOL -> della8_tool.default
            DesignSystem.Image.WHY_DELLA8_BANNER -> why_della8_banner.default
            DesignSystem.Image.WHY_DELLA8_PRICE -> why_della8_price.default
            DesignSystem.Image.WHY_DELLA8_STARS -> why_della8_stars.default
            DesignSystem.Image.MATCH -> match.default
            DesignSystem.Image.PLAY -> play.default
            DesignSystem.Image.CLOSE -> close.default
            DesignSystem.Image.MOCK -> mock.default
            DesignSystem.Image.PRICEHINT -> pricehint.default
            DesignSystem.Image.RESUSR -> resurs.default
            DesignSystem.Image.OBJECTS_TO_COWN -> objects_to_coown.default
            DesignSystem.Image.LOGO_BENGT -> logo_bengt.default
            DesignSystem.Image.LOGO_RESURS -> logo_resurs.default
            DesignSystem.Image.LOGO_PROJECT_WINTER_VILLAGE -> logo_vinterbyn.default
            DesignSystem.Image.OBJECTS_OWN -> objects_own.default
            DesignSystem.Image.VIDEO -> video_preview.default
            DesignSystem.Image.DELLA_AVATAR_HAPPY -> della_avatar_happy.default
            DesignSystem.Image.DELLA_AVATAR_GREETING -> della_avatar_greeting.default
            DesignSystem.Image.EMPTY_CONTRACT -> empty_contract.default
            DesignSystem.Image.YACHT_HOME -> yacht.default
            DesignSystem.Image.MINIMIZE -> minimize.default
            DesignSystem.Image.DELLA_INFO -> della_info.default
            null -> null
        }
    }

    fun imageAltText(image: DesignSystem.Image?): String {
        return when (image) {
            DesignSystem.Image.ARTICLE_LOGO -> "Della8"
            DesignSystem.Image.ARRIVAL -> "Ankomst"
            DesignSystem.Image.BACK -> "Tillbaka"
            DesignSystem.Image.BANK_ID -> "BankId"
            DesignSystem.Image.BACK_WHITE -> "Tillbaka"
            DesignSystem.Image.CHECKED -> "Markerad"
            DesignSystem.Image.CHECK -> "Markera"
            DesignSystem.Image.DEPARTURE -> "Avgång"
            DesignSystem.Image.INVITE -> "Bjud in"
            DesignSystem.Image.LOGO -> "Della8"
            DesignSystem.Image.LOGO_LIGHT -> "Della8"
            DesignSystem.Image.NEXT -> "Nästa"
            DesignSystem.Image.PREVIOUS -> "Tidigare"
            DesignSystem.Image.SETTINGS -> "Inställningar"
            DesignSystem.Image.SHOP -> "Butik"
            DesignSystem.Image.INVITES -> "Inbjudningar"
            DesignSystem.Image.COPY -> "Kopiera"
            DesignSystem.Image.CODE -> "Inbjudningskod"
            DesignSystem.Image.START -> "Start"
            DesignSystem.Image.AGREEMENT -> "Avtal"
            DesignSystem.Image.AGREEMENT_SM -> "Avtal"
            DesignSystem.Image.NO_AGREEMENT -> "Inget avtal"
            DesignSystem.Image.BOOKED_OTHER -> "Bokad av annan"
            DesignSystem.Image.BOOKED_ME -> "Bokad av mig"
            DesignSystem.Image.LOCKED -> "Låst"
            DesignSystem.Image.COMMON -> "Vanlig"
            DesignSystem.Image.RULEBASED -> "Regelbaserad"
            DesignSystem.Image.VERSION -> "Version"
            DesignSystem.Image.DOWNLOAD -> "Nedladdning"
            DesignSystem.Image.INFO -> "Info"
            DesignSystem.Image.RULES -> "Regler"
            DesignSystem.Image.CALENDAR -> "Kalender"
            DesignSystem.Image.GOLD -> "Guldvecka"
            DesignSystem.Image.BLOCK_BOOKING -> "Bokning"
            DesignSystem.Image.BLOCK_APPROVE -> "Godkänn block"
            DesignSystem.Image.BLOCK_CONNECTED -> "Ansluten block"
            DesignSystem.Image.BLOCK_INVITE -> "Bjud in samägare"
            DesignSystem.Image.BLOCK_OWNERSHIP -> "Samägande"
            DesignSystem.Image.TRASH -> "Skräp"
            DesignSystem.Image.RELATED -> "Relaterad"
            DesignSystem.Image.CROWN -> "Admin krona"
            DesignSystem.Image.GAME_RULES -> "Spelregler"
            DesignSystem.Image.HAPPINESS -> "Framgång grön bock"
            DesignSystem.Image.COPIED -> "Kopierad"
            DesignSystem.Image.FAILURE -> "Misslyckande"
            DesignSystem.Image.ARROWSCROLLDOWN -> "Scrolla ned"
            DesignSystem.Image.LINKEDIN -> "LinkedIn"
            DesignSystem.Image.INSTAGRAM -> "Instagram"
            DesignSystem.Image.LAUNCH -> "Lansering"
            DesignSystem.Image.MOTOR_HOME -> "Husbil"
            DesignSystem.Image.HOLIDAY_HOME -> "Fritidshus"
            DesignSystem.Image.BOOKING_GOLD -> "Guld bokning"
            DesignSystem.Image.BOOKING_LOCK -> "Låst bokning"
            DesignSystem.Image.BOOKING_TODAY -> "Dagens bokning"
            DesignSystem.Image.TIP -> "Tips"
            DesignSystem.Image.NOOBJECT -> "Inget objekt"
            DesignSystem.Image.BANNERTESTPILOTS -> "Banner testpiloter"
            DesignSystem.Image.BOOKING_GOLD_LOCKED -> "Låst guld bokning"
            DesignSystem.Image.RULE_ON_BTN -> "Regel på"
            DesignSystem.Image.RULE_OFF_BTN -> "Regel av"
            DesignSystem.Image.RULE_ON_BLACK -> "Regel på (info)"
            DesignSystem.Image.RULE_OFF_BLACK -> "Regel av (info)"
            DesignSystem.Image.OTHER_OBJECT -> "Annat objekt"
            DesignSystem.Image.PRESENT_AGREEMENT -> "Aktuellt avtal"
            DesignSystem.Image.OTHER_OBJECT_XL -> "Annat objekt"
            DesignSystem.Image.HOLIDAY_HOME_XL -> "Fritidshus"
            DesignSystem.Image.MOTOR_HOME_XL -> "Husbil"
            DesignSystem.Image.PRESENT_AGREEMENT_XL -> "Aktuellt avtal"
            DesignSystem.Image.UPDATES -> "Uppdateringar"
            DesignSystem.Image.AGREEMENT_UPDATES -> "Avtalsuppdateringar"
            DesignSystem.Image.HIGHLIGHT -> "Info i"
            DesignSystem.Image.CONTRACT_DRAFT -> "Kontraktsutkast"
            DesignSystem.Image.STEP1 -> "Steg 1"
            DesignSystem.Image.STEP2 -> "Steg 2"
            DesignSystem.Image.STEP3 -> "Steg 3"
            DesignSystem.Image.STEP4 -> "Steg 4"
            DesignSystem.Image.STEP5 -> "Steg 5"
            DesignSystem.Image.STEP6 -> "Steg 6"
            DesignSystem.Image.STEP7 -> "Steg 7"
            DesignSystem.Image.STEP8 -> "Steg 8"
            DesignSystem.Image.STEP9 -> "Steg 9"
            DesignSystem.Image.STEP10 -> "Steg 10"
            DesignSystem.Image.FEED -> "Flöde"
            DesignSystem.Image.FEED_EDIT -> "Redigera flöde"
            DesignSystem.Image.FEED_LIKE_FILLED -> "Gilla (ifylld)"
            DesignSystem.Image.FEED_LIKE_OUTLINED -> "Gilla (omark.)"
            DesignSystem.Image.FEED_REACTIONS -> "Reaktioner"
            DesignSystem.Image.FEED_UPDATES -> "Uppdatera meddelande"
            DesignSystem.Image.VOTE -> "Rösta"
            DesignSystem.Image.VOTE_SUCCESS -> "Röstning lyckad"
            DesignSystem.Image.CLIPPY -> "Olivia pratar"
            DesignSystem.Image.CALCULATOR_BOAT_CHEAP -> "Billig båtkalkyl"
            DesignSystem.Image.CALCULATOR_BOAT_EXPENSIVE -> "Dyr båtkalkyl"
            DesignSystem.Image.CALCULATOR_HOLIDAYHOME_CHEAP -> "Billig fritidshuskalkyl"
            DesignSystem.Image.CALCULATOR_HOLIDAYHOME_EXPENSIVE -> "Dyr fritidshuskalkyl"
            DesignSystem.Image.CALCULATOR_MOTORHOME_EXPENSIVE -> "Dyr husbilkalkyl"
            DesignSystem.Image.CALCULATOR_MOTORHOME_CHEAP -> "Billig husbilkalkyl"
            DesignSystem.Image.CALCULATOR_OWN_SETTINGS -> "Anpassade inställningar"
            DesignSystem.Image.DELLA_TOOL -> "Della8-verktyg"
            DesignSystem.Image.WHY_DELLA8_BANNER -> "Varför Della8-banner"
            DesignSystem.Image.WHY_DELLA8_PRICE -> "Varför Della8-pris"
            DesignSystem.Image.WHY_DELLA8_STARS -> "Varför Della8-stjärnor"
            DesignSystem.Image.MATCH -> "Matchning"
            DesignSystem.Image.PLAY -> "Spela"
            DesignSystem.Image.CLOSE -> "Stäng"
            DesignSystem.Image.MOCK -> "Mock"
            DesignSystem.Image.PRICEHINT -> "Prisindikation"
            DesignSystem.Image.RESUSR -> "Resurs"
            DesignSystem.Image.OBJECTS_TO_COWN -> "Objekt att dela"
            DesignSystem.Image.LOGO_BENGT -> "Bengt i Örkelljunga logotyp"
            DesignSystem.Image.LOGO_RESURS -> "Resurs logotyp"
            DesignSystem.Image.LOGO_PROJECT_WINTER_VILLAGE -> "Vinterbyns projektlogotyp"
            DesignSystem.Image.OBJECTS_OWN -> "Egna objekt"
            DesignSystem.Image.VIDEO -> "Videoförhandsvisning"
            DesignSystem.Image.DELLA_AVATAR_HAPPY -> "Della-avatar (glad)"
            DesignSystem.Image.DELLA_AVATAR_GREETING -> "Della-avatar (hälsning)"
            DesignSystem.Image.EMPTY_CONTRACT -> "Tomt kontrakt"
            DesignSystem.Image.YACHT_HOME -> "Båt"
            DesignSystem.Image.MINIMIZE -> "Minimera"
            DesignSystem.Image.DELLA_INFO -> "Info"
            else -> image?.toString()?.lowercase()?.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() } ?: ""
        }
    }


    fun animation(animation: DesignSystem.Animation?): dynamic {
        return when (animation) {
            DesignSystem.Animation.SPINNER -> spinner
            DesignSystem.Animation.LIKE -> like2
            null -> null
        }
    }

    fun movieHTML5(image: DesignSystem.Movie?): String? {
        return when (image) {
            DesignSystem.Movie.NONE, null -> null
            DesignSystem.Movie.CAMPING -> camping.default
            DesignSystem.Movie.FIREWORKS -> fireworks.default
            DesignSystem.Movie.INTRODUCTION -> null
            DesignSystem.Movie.MOUNTAINS -> mountains.default
            DesignSystem.Movie.PITCH -> null
            DesignSystem.Movie.POOL -> pool.default
            DesignSystem.Movie.SNOW -> snow.default
            DesignSystem.Movie.FORREST -> forrest.default
            DesignSystem.Movie.SEA -> sea.default
            DesignSystem.Movie.CAMPING_XS -> camping_xs.default
            DesignSystem.Movie.MOUNTAINS_XS -> mountains_xs.default
            DesignSystem.Movie.POOL_XS -> pool_xs.default
            DesignSystem.Movie.SNOW_XS -> snow_xs.default
            DesignSystem.Movie.FORREST_XS -> forrest_xs.default
            DesignSystem.Movie.SEA_XS -> sea_xs.default
        }
    }

    fun movieMUX(image: DesignSystem.Movie?): String? {
        return when (image) {
            DesignSystem.Movie.NONE, null -> null
            DesignSystem.Movie.CAMPING -> null
            DesignSystem.Movie.FIREWORKS -> "7pcAtBC6N85ayoe6e00ogm2ISEPtynQUK4isMCmNZkGc"
            DesignSystem.Movie.INTRODUCTION -> "cXVN6PbZZkw02wXq9z5algETsq8FiWuovcrDNov25PVY"
            DesignSystem.Movie.MOUNTAINS -> null
            DesignSystem.Movie.PITCH -> "LQRtlhbPjmGQ5cjIoYwlRa6xkubE02iyCAcSzXYPsSzE"
            DesignSystem.Movie.POOL -> null
            DesignSystem.Movie.SNOW -> null
            DesignSystem.Movie.FORREST -> null
            DesignSystem.Movie.SEA -> null
            DesignSystem.Movie.CAMPING_XS -> null
            DesignSystem.Movie.MOUNTAINS_XS -> null
            DesignSystem.Movie.POOL_XS -> null
            DesignSystem.Movie.SNOW_XS -> null
            DesignSystem.Movie.FORREST_XS -> null
            DesignSystem.Movie.SEA_XS -> null
        }
    }

    fun color(color: DesignSystem.Color?): String? {
        return when (color) {
            DesignSystem.Color.CLEAR -> Della8Theme.colors.clear
            DesignSystem.Color.DUSTY -> Della8Theme.colors.dusty
            DesignSystem.Color.OCEAN -> Della8Theme.colors.ocean
            DesignSystem.Color.LAVENDER -> Della8Theme.colors.lavender
            DesignSystem.Color.MARINE -> Della8Theme.colors.marine
            DesignSystem.Color.NEON -> Della8Theme.colors.neon
            DesignSystem.Color.BLUEBERRY -> Della8Theme.colors.blueberry
            DesignSystem.Color.PEACH -> Della8Theme.colors.peach
            DesignSystem.Color.BATTLESHIP -> Della8Theme.colors.battleship
            DesignSystem.Color.SEA -> Della8Theme.colors.sea
            DesignSystem.Color.RUBY -> Della8Theme.colors.ruby
            DesignSystem.Color.PEARL -> Della8Theme.colors.pearl
            DesignSystem.Color.SNOW -> Della8Theme.colors.snow
            null -> null
        }
    }
}