package della8.core.support

import techla.base.*
import techla.reservation.Reservation

sealed class DesignSystem(open val visible: Boolean = true) {
    // Helpers
    enum class Action { NONE, BACK, PREVIOUS, NEXT, CONTACT, LOGOUT, SETTINGS, SHOP, INVITE, AGREEMENT, COOKIES, PRIVACY, INSTAGRAM, LINKEDIN, FEED, EDIT, REMOVE, COMMENT, LIKE, PRESS_KIT, DELLA8_HOME, REACTIONS }
    enum class Annotation { BOOKED_OTHER_START, BOOKED_OTHER, BOOKED_OTHER_END, BOOKED_ME_START, BOOKED_ME, BOOKED_ME_END, GOLD_START, GOLD, GOLD_END, TODAY, LOCKED, ONGOING_START, ONGOING, ONGOING_END }
    data class Cell(
        val id: Identifier<Cell> = Identifier(),
        val text: String = "",
        val annotations: List<Annotation> = emptyList(),
        val reservations: List<Identifier<Reservation>> = emptyList(),
    ) {
        companion object

        val isGold
            get() =
                annotations.any { it == Annotation.GOLD_START || it == Annotation.GOLD || it == Annotation.GOLD_END }
    }

    enum class Color { CLEAR, DUSTY, OCEAN, LAVENDER, MARINE, NEON, BLUEBERRY, PEACH, BATTLESHIP, SEA, RUBY, PEARL, SNOW }
    enum class Image {
        ARTICLE_LOGO, ARRIVAL, BACK, BACK_WHITE, BACKGROUND, BANK_ID, CHECKED, CHECK, INVITE, LOGO, LOGO_LIGHT, CODE, DEPARTURE, NEXT, PREVIOUS, SETTINGS, SHOP, START, INVITES, COPY, AGREEMENT, NO_AGREEMENT, AGREEMENT_SM, BOOKED_OTHER, BOOKED_ME, LOCKED, COMMON, RULEBASED, DOWNLOAD, VERSION, INFO, RULES, CALENDAR, GOLD, BLOCK_BOOKING, BLOCK_APPROVE, BLOCK_CONNECTED, BLOCK_INVITE, BLOCK_OWNERSHIP, TRASH, RELATED, CROWN, GAME_RULES, HAPPINESS, COPIED, FAILURE, LINKEDIN, INSTAGRAM, ARROWSCROLLDOWN, LAUNCH, MOTOR_HOME, HOLIDAY_HOME, BOOKING_GOLD, BOOKING_LOCK, BOOKING_TODAY, TIP, NOOBJECT, BANNERTESTPILOTS, BOOKING_GOLD_LOCKED, RULE_ON_BTN, RULE_OFF_BTN, RULE_ON_BLACK, RULE_OFF_BLACK, OTHER_OBJECT, PRESENT_AGREEMENT, OTHER_OBJECT_XL, HOLIDAY_HOME_XL, PRESENT_AGREEMENT_XL, MOTOR_HOME_XL, UPDATES, AGREEMENT_UPDATES, HIGHLIGHT, CONTRACT_DRAFT, STEP1, STEP2, STEP3, STEP4, STEP5, STEP6, STEP7, STEP8, STEP9, STEP10, FEED, FEED_EDIT, FEED_LIKE_FILLED, FEED_LIKE_OUTLINED, FEED_REACTIONS, FEED_UPDATES, VOTE, VOTE_SUCCESS, CLIPPY, CALCULATOR_BOAT_CHEAP, CALCULATOR_BOAT_EXPENSIVE, CALCULATOR_HOLIDAYHOME_CHEAP, CALCULATOR_HOLIDAYHOME_EXPENSIVE, CALCULATOR_MOTORHOME_EXPENSIVE, CALCULATOR_MOTORHOME_CHEAP, CALCULATOR_OWN_SETTINGS, DELLA8_EMELIE, DELLA8_MAGNUS, DELLA8_OLIVIA, DELLA8_MICKE, DELLA8_JOHANNA, DELLA8_NIKLAS, DELLA8_NAOMI, DELLA_TOOL, WHY_DELLA8_BANNER, WHY_DELLA8_PRICE, WHY_DELLA8_STARS, MATCH, PLAY, CLOSE, MOCK, PRICEHINT, RESUSR, OBJECTS_TO_COWN, LOGO_BENGT, LOGO_RESURS, LOGO_PROJECT_WINTER_VILLAGE, OBJECTS_OWN, VIDEO, DELLA_AVATAR_HAPPY, DELLA_AVATAR_GREETING, EMPTY_CONTRACT, YACHT_HOME, MINIMIZE, DELLA_INFO
    }

    enum class Movie { NONE, CAMPING, FIREWORKS, INTRODUCTION, MOUNTAINS, PITCH, POOL, SNOW, FORREST, SEA, CAMPING_XS, MOUNTAINS_XS, POOL_XS, SNOW_XS, FORREST_XS, SEA_XS }
    enum class MoviePlayer { HTML5, YOUTUBE, MUX }
    enum class Animation { SPINNER, LIKE }
    enum class TextStyle { MEGA, LARGE_TITLE, BODY, FOOTNOTE, HEADLINE, TITLE1, TITLE2, TITLE3, SUBHEAD }
    enum class ButtonStyle { PRIMARY, SECONDARY, TERTIARY, TRANSPARENT, IMAGE }
    enum class SelectStyle { REGULAR, DROP_UP, DROP_DOWN, SWITCH }
    enum class SliderInputStyle { INPUT_ONLY, SLIDER_ONLY, INPUT_AND_SLIDER }
    enum class ProgressStyle { DRAFT, PENDING, APPROVED }
    enum class PropertyStyle { VERTICAL, HORIZONTAL }
    enum class Background { LIGHT, DARK }
    enum class FileType { ALL, AUDIO, VIDEO, IMAGE, PDF }


    enum class IconAlignment { LEFT, RIGHT, CENTER, TOP, }
    enum class TextAlignment { NONE, CENTER, RIGHT, LEFT }

    enum class Input { EMAIL, GOV_ID, NUMBER, DECIMAL, TEXT, NOVEL, REGISTRATION }

    sealed class Option(open val title: String?, open val value: String?, open val data: Any?, open val visible: Boolean) {
        data class Item(
            override val title: String?,
            override val value: String?,
            override val data: Any?,
            override val visible: Boolean = true,
            val image: Image?,
            val movie: Movie?,
            val action: Action?,
            val disabled: Boolean,
            val location: Location?,
        ) : Option(title = title, value = value, data = data, visible = visible)

        data class Other(
            override val title: String?,
            override val value: String?,
            override val data: Any?,
            override val visible: Boolean = true,
            val image: Image?,
            val other: String?,
        ) : Option(title = title, value = value, data = data, visible = visible)

        val isDisabled
            get() =
                when (this) {
                    is Item -> disabled
                    is Other -> false
                }

        val imageValue
            get() =
                when (this) {
                    is Item -> image
                    is Other -> image
                }

        val movieValue
            get() =
                when (this) {
                    is Item -> movie
                    is Other -> null
                }

        val actionValue
            get() =
                when (this) {
                    is Item -> action
                    is Other -> null
                }

        val locationValue
            get() =
                when (this) {
                    is Item -> location
                    is Other -> null
                }
        val isVisible
            get() =
                when (this) {
                    is Item -> visible
                    is Other -> false
                }

        companion object {
            val None =
                Item(title = null, image = null, movie = null, action = null, value = null, data = null, disabled = false, location = null, visible = false)

            fun back(title: String, image: Image? = Image.BACK, action: Action? = Action.BACK) =
                Item(title = title, image = image, movie = null, action = action, value = null, data = null, disabled = false, location = null)

            fun previous(title: String) =
                Item(title = title, image = Image.PREVIOUS, movie = null, action = Action.PREVIOUS, value = null, data = null, disabled = false, location = null)

            fun next(title: String) =
                Item(title = title, image = Image.NEXT, movie = null, action = Action.NEXT, value = null, data = null, disabled = false, location = null)

            fun copy(title: String) =
                Item(title = title, image = Image.COPY, movie = null, action = Action.NEXT, value = null, data = null, disabled = false, location = null)

            fun item(title: String? = null, image: Image? = null, movie: Movie? = null, action: Action? = null, value: String? = null, data: Any? = null, disabled: Boolean = false, location: Location? = null, visible: Boolean = true) =
                Item(title = title, image = image, movie = movie, action = action, value = value, data = data, disabled = disabled, location = location, visible = visible)

            fun other(title: String? = null, image: Image? = null, other: String? = null, value: String? = null, data: Any? = null) =
                Other(title = title, image = image, other = other, value = value, data = data)
        }
    }

    data class ListItem(
        val index: Int,
        val text: Text? = null,
        val text2: Text? = null,
        val action: Button? = null,
    )

    data class Row(
        val text: String,
        val cells: List<Cell>,
        val annotations: List<Annotation> = emptyList()
    ) {
        companion object
    }

    data class Header(
        val id: String
    ) {
        companion object
    }

    sealed class Status {
        object Unknown : Status()
        object Valid : Status()
        data class Invalid(val warning: String? = null) : Status()

        val message: String?
            get() =
                when (val s = this) {
                    is Unknown, is Valid -> null
                    is Invalid -> s.warning
                }
    }

    object None : DesignSystem()

    // Navigations
    data class Navigation(
        val title: String? = null,
        val navigation: Option? = null,
        val menu: Menu? = null,
        val navigationButtons: List<Button>? = null,
        val objectButtons: List<Button>? = null,
        val background: Background = Background.DARK,
        val movie: MovieView? = null,
        val location: Location? = null, // Where to go when logo is clicked
    ) : DesignSystem() {
        companion object
    }

    data class Menu(val items: List<Option>, val selected: Option? = null) : DesignSystem()

    // Elements
    data class Draft(
        val title: String? = null,
        val style: ProgressStyle = ProgressStyle.APPROVED,
        override val visible: Boolean = true
    ) : DesignSystem(visible) {
        companion object
    }

    data class Progress(val info: Text, override val visible: Boolean = true) : DesignSystem(visible) {
        companion object
    }

    data class Failure(
        val image: ImageView = ImageView(),
        val title: Text,
        val details: Text,
        val recover: Button = Button(),
        val shouldLogout: Boolean = false,
        val automaticLogout: Boolean = false
    ) : DesignSystem() {
        companion object
    }

    data class Success(val image: ImageView, val title: Text, val info: Text, val next: Button) : DesignSystem() {
        companion object
    }

    data class Profile(
        val initials: String,
        val leader: Boolean = false,
        val approved: Boolean = false,
        val pending: Boolean = false
    ) : DesignSystem() {
        companion object
    }

    data class Property(
        val header: Text? = null,
        val value: Text? = null,
        val prefix: Text? = null,
        val suffix: Text? = null,
        val style: PropertyStyle = PropertyStyle.VERTICAL,
        override val visible: Boolean = true,
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class Month(val id: Identifier<Month> = Identifier(), val month: Text, val year: Text, val rows: List<Row>) :
        DesignSystem() {
        companion object
    }


    data class Modal(val title: Text? = null, val body: Text? = null, val firstButton: Button? = null, val secondButton: Button? = null, val centerButton: Boolean = false, override val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    data class ListView(val title: Text? = null, val items: List<ListItem>? = null, override val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    // Primitives
    data class Text(
        val text: String? = null,
        val style: TextStyle = TextStyle.BODY,
        val background: Background = Background.LIGHT,
        val textColor: Color? = null,
        val isMarkdown: Boolean = false,
        val image: Image? = null,
        val iconAlignment: IconAlignment? = null,
        val alignment: TextAlignment = TextAlignment.NONE,
        override val visible: Boolean = true
    ) : DesignSystem() {
        companion object
    }

    // Inputs
    data class TextInput(
        val header: Header,
        val title: String? = null,
        val value: String? = null,
        val input: Input = Input.TEXT,
        val disabled: Boolean = false,
        val status: Status? = null,
        val placeholder: String? = null,
        val unit: String? = null,
        val rows: String = "4",
        override val visible: Boolean = true
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class FileInput(
        val header: Header,
        val title: String? = null,
        val disabled: Boolean = false,
        val status: Status? = null,
        val placeholder: String? = null,
        val invisible: Boolean = false,
        val multiple: Boolean = false,
        val fileType: FileType = FileType.ALL,
        override val visible: Boolean = true
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class BooleanInput(
        val header: Header? = null,
        val title: String? = null,
        val value: Boolean? = null,
        val disabled: Boolean = false,
        override val visible: Boolean = true,
        val status: Status? = null
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class MovieInput(
        val header: Header? = null,
        val title: String? = null,
        val options: List<Option> = emptyList(),
        val selected: Option? = null,
        override val visible: Boolean = true,
        val status: Status? = null
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class ImageInput(
        val header: Header? = null,
        val title: String? = null,
        val options: List<Option> = emptyList(),
        val selected: Option? = null,
        val status: Status? = null,
        override val visible: Boolean = true,
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class RadioInput(
        val header: Header? = null,
        val title: String? = null,
        val buttons: List<RadioButton> = emptyList(),
        val selected: RadioButton? = null,
        val status: Status? = null,
        override val visible: Boolean = true,
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class SelectInput(
        val header: Header,
        val title: String? = null,
        val options: List<Option> = emptyList(),
        val selected: Option? = null,
        val status: Status? = null,
        val style: SelectStyle = SelectStyle.REGULAR,
        override val visible: Boolean = true,
    ) : DesignSystem(visible = visible) {
        companion object
    }


    data class SliderInput(
        val header: Header,
        val title: String? = null,
        val min: Int = 0,
        val max: Int = 100,
        val step: Int = 1,
        val selected: Int? = null,
        val unit: String? = null,
        val status: Status? = null,
        val disabled: Boolean = false,
        val style: SliderInputStyle = SliderInputStyle.INPUT_AND_SLIDER,
        override val visible: Boolean = true,
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class Button(
        val title: String? = null,
        val image: Image? = null,
        val background: Color = Color.SEA,
        val action: Action? = null,
        val location: Location? = null,
        val data: Any? = null,
        val style: ButtonStyle = ButtonStyle.PRIMARY,
        val iconAlignment: IconAlignment = IconAlignment.LEFT,
        val disabled: Boolean = false,
        val selected: Boolean = false,
        override val visible: Boolean = true,
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class Footer(
        val title: Text,
        val body: Text,
        val buttons: List<Button>? = null,
        val links: List<Button>? = null,
        val background: Background = Background.DARK,
        override val visible: Boolean = true,
    ) : DesignSystem() {
        companion object
    }

    data class ImageButton(
        val option: Option? = null,
        val selected: Boolean = false,
        override val visible: Boolean = true
    ) : DesignSystem() {
        companion object
    }

    data class RadioButton(
        val title: String? = null,
        val status: Status? = null,
        override val visible: Boolean = true
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class MenuButton(val title: String? = null, val menu: Menu? = null, val selected: Option? = null, override val visible: Boolean = true) :
        DesignSystem() {
        companion object
    }

    data class Icon(val image: Image? = null, val background: Color? = null) : DesignSystem() {
        companion object
    }

    data class ImageView(
        val href: String? = null,
        val alt: String? = null,
        val image: Image? = null,
        val location: Location? = null,
        override val visible: Boolean = true
    ) : DesignSystem() {
        companion object
    }

    data class Carousel(
        val images: List<ImageView>,
        override val visible: Boolean = true
    ) : DesignSystem() {
        companion object
    }

    data class MovieView(val movie: Movie? = null, val href: String? = null, val autoplay: Boolean = true, val loop: Boolean = true, val playsInline: Boolean = true, val muted: Boolean = true, val showControls: Boolean = false, val player: MoviePlayer = MoviePlayer.HTML5, val muxPlaybackToken: String? = null, val muxThumbnailToken: String? = null, override val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    data class Clippy(val title: Text, val image: ImageView, val minimize: Button, val maximize: Button, override val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    data class FAQ(val contents: List<Pair<String, String>>, override val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    data class Meta(val title: String, val description: String, val type: String, val url: String, val image: String) : DesignSystem() {
        companion object
    }
}

fun List<Pair<DesignSystem.Header, DesignSystem.Status>>.statusOf(header: DesignSystem.Header): DesignSystem.Status? =
    firstOrNull { it.first == header }?.second

fun List<DesignSystem.Status>.overallStatus(): DesignSystem.Status =
    when {
        any { it is DesignSystem.Status.Invalid } -> DesignSystem.Status.Invalid()
        all { it is DesignSystem.Status.Valid } -> DesignSystem.Status.Valid
        else -> DesignSystem.Status.Unknown
    }

fun List<Pair<DesignSystem.Header, DesignSystem.Status>>.overallStatus(): DesignSystem.Status =
    map { it.second }.overallStatus()

interface ProgressTexts {
    val progressInfo: String
}

fun progress(texts: ProgressTexts) =
    DesignSystem.Progress(
        info = DesignSystem.Text(text = texts.progressInfo, style = DesignSystem.TextStyle.BODY, alignment = DesignSystem.TextAlignment.CENTER)
    )

interface FailureTexts {
    val failureTitle: String
}

fun failure(texts: FailureTexts, failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): DesignSystem.Failure {
    techla_log("FAILURE: $failure")

    var shouldLogout = false
    val message = when (failure) {
        is Either.Left -> failure.value.joinToString(", ")
        is Either.Right ->
            when (failure.value) {
                is TechlaError.Unauthorized -> {
                    shouldLogout = true
                    "Din inlogging har gått ut och du behöver logga in på nytt."
                }

                is TechlaError.ServiceUnavailable -> "Internetanslutningen verkar vara nedkopplad."
                else -> failure.value.message
            }
    }

    val title = if (shouldLogout)
        ""
    else
        texts.failureTitle

    return DesignSystem.Failure(
        image = DesignSystem.ImageView(image = DesignSystem.Image.FAILURE),
        title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.TITLE1),
        details = DesignSystem.Text(
            text = message ?: "Ett okänt fel har inträffat, var god försök senare",
            style = DesignSystem.TextStyle.SUBHEAD,
        ),
        recover = if (shouldLogout)
            DesignSystem.Button(title = "Logga ut")
        else
            DesignSystem.Button(title = "Försök igen"),
        shouldLogout = shouldLogout,
        automaticLogout = automaticLogout
    )
}

interface SuccessTexts {
    val successTitle: String
    val successNext: String
    val successInfo: String
}

fun success(texts: SuccessTexts, background: DesignSystem.Background = DesignSystem.Background.LIGHT) =
    DesignSystem.Success(
        image = DesignSystem.ImageView(image = DesignSystem.Image.HAPPINESS),
        title = DesignSystem.Text(
            text = texts.successTitle,
            style = DesignSystem.TextStyle.TITLE1,
            background = background
        ),
        info = DesignSystem.Text(
            text = texts.successInfo,
            style = DesignSystem.TextStyle.BODY,
            background = background
        ),
        next = DesignSystem.Button(title = texts.successNext),
    )

interface ClippyTexts {
    // val clippyTitle: String
}

fun clippy(texts: ClippyTexts, visible: Boolean = true) =
    DesignSystem.Clippy(
        visible = visible,
        title = DesignSystem.Text(text = "Della hjälper dig komma igång", style = DesignSystem.TextStyle.HEADLINE, background = DesignSystem.Background.DARK),
        image = DesignSystem.ImageView(image = DesignSystem.Image.DELLA_AVATAR_HAPPY),
        minimize = DesignSystem.Button(style = DesignSystem.ButtonStyle.IMAGE, image = DesignSystem.Image.MINIMIZE, background = DesignSystem.Color.CLEAR),
        maximize = DesignSystem.Button(style = DesignSystem.ButtonStyle.IMAGE, image = DesignSystem.Image.DELLA_INFO, background = DesignSystem.Color.CLEAR)
    )

interface MetaTexts {
    val metaTitle: String
    val metaDescription: String
}

fun meta(texts: MetaTexts) =
    DesignSystem.Meta(
        title = texts.metaTitle,
        description = texts.metaDescription,
        type = "website",
        url = "",
        image = "img/img_header.jpg",
    )
