package della8.core.support

import techla.agreement.Agreement
import techla.guard.Group

val Group.Visualization.movie: DesignSystem.Movie
    get() =
        when (this) {
            is Group.Visualization.Predefined ->
                when (template) {
                    "1" -> DesignSystem.Movie.MOUNTAINS
                    "2" -> DesignSystem.Movie.POOL
                    "3" -> DesignSystem.Movie.CAMPING
                    "4" -> DesignSystem.Movie.SNOW
                    "5" -> DesignSystem.Movie.SEA
                    "6" -> DesignSystem.Movie.FORREST
                    else -> DesignSystem.Movie.NONE
                }

            else -> DesignSystem.Movie.NONE
        }


fun Agreement.Status.draft(draft: String, pending: String): DesignSystem.Draft =
    when (this) {
        is Agreement.Status.Unknown -> DesignSystem.Draft(visible = false)
        is Agreement.Status.Draft -> DesignSystem.Draft(title = draft, style = DesignSystem.ProgressStyle.DRAFT, visible = true)
        is Agreement.Status.Pending -> DesignSystem.Draft(title = pending, style = DesignSystem.ProgressStyle.PENDING, visible = true)
        is Agreement.Status.Approved -> DesignSystem.Draft(visible = false)
    }


fun Group.Status.draft(draft: String, pending: String): DesignSystem.Draft =
    when (this) {
        !is Group.Status.Active -> DesignSystem.Draft(title = draft, style = DesignSystem.ProgressStyle.DRAFT, visible = true)
        else -> DesignSystem.Draft(visible = false)
    }


val DesignSystem.Movie.visualization
    get() =
        when (this) {
            DesignSystem.Movie.MOUNTAINS, DesignSystem.Movie.MOUNTAINS_XS -> Group.Visualization.Predefined("1")
            DesignSystem.Movie.POOL, DesignSystem.Movie.POOL_XS -> Group.Visualization.Predefined("2")
            DesignSystem.Movie.CAMPING, DesignSystem.Movie.CAMPING_XS -> Group.Visualization.Predefined("3")
            DesignSystem.Movie.SNOW, DesignSystem.Movie.SNOW_XS -> Group.Visualization.Predefined("4")
            DesignSystem.Movie.SEA, DesignSystem.Movie.SEA_XS -> Group.Visualization.Predefined("5")
            DesignSystem.Movie.FORREST, DesignSystem.Movie.FORREST_XS -> Group.Visualization.Predefined("6")
            else -> Group.Visualization.None
        }
